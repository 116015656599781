export function login(params) {
  return {
    type: 'LOGIN',
    payload: {
      request: {
        method: 'post',
        url: '/login',
        data: params
      }
    }
  }
}

export function login_with_token(token) {
  return {
    type: 'LOGIN_WITH_TOKEN',
    payload: {
      request: {
        method: 'post',
        url: '/login_with_token',
        data: { token }
      }
    }
  }
}

export function create_user_from_pid(pid) {
  return {
    type: 'CREATE_USER_FROM_PID',
    payload: {
      request: {
        method: 'post',
        url: '/create_user_from_pid',
        data: { pid }
      }
    }
  }
}

export function logout() {
  return {
    type: 'LOGOUT',
    payload: {
      request: {
        url: '/logout',
      }
    }
  }
}

export function get_user_or_prospect(pid) {
  return {
    type: 'GET_USER_OR_PROSPECT',
    payload: {
      request: {
        url: `/get_user_or_prospect?pid=${pid}`
      }
    }
  }
}

export function get_user() {
  return {
    type: 'GET_USER',
    payload: {
      request: {
        url: '/get_user'
      }
    }
  }
}

export function sign_up(params) {
  return {
    type: 'SIGN_UP',
    payload: {
      request: {
        method: 'post',
        url: '/sign_up',
        data: params
      }
    }
  }
}
