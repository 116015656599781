import { css } from '@emotion/react';

const style = css`
  .title {
    text-align: center;
    font-family: BentonSans-Bold;
  }
  .subtitle {
    text-align: center;
    margin-bottom: 30px;
    font-family: BentonSans-Medium;
  }
  .vimeo-full-width {
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
    overflow: hidden;
    transform: translateY(0px);
    border-radius: 0.25rem;
    padding: 67.60% 0 0 0;
    position: relative;
    margin-top: 1.5rem;
  }
  .vimeo-full-width iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const VideoSection = () => {
  return (
      <section className="section" id="video" css={style}>
        <div className="container is-max-desktop">
          <div className="columns is-mobile is-centered">
            <div className="column has-text-centered">
              <h1 className="title is-2 is-size-3-mobile is-spaced">See the Tool in Action</h1>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column">
              <div className="vimeo-full-width">
                <iframe title="Tutorial Video" src="https://player.vimeo.com/video/819883311?byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default VideoSection;
