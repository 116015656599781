import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material'
import Home from './features/Home';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { AxiosInstanceProvider } from '@context/AxiosContext';
import { AnalyticsProvider } from '@context/AnalyticsContext';
import { SnackbarProvider } from 'notistack';
import SignUp from './features/SignUp';
import GetUserOrProspect from '@features/GetUserOrProspect';
import SubscriptionReadiness from '@features/SubscriptionReadiness';
import Dashboard from '@features/Dashboard';
import Login from '@features/Login';
import ForgotPassword from '@features/ForgotPassword';
import ResetPassword from '@features/ResetPassword';
import WithLoader from '@features/WithLoader';
import PageViews from '@features/PageViews';
import Identify from '@features/Identify';
import TermsOfService from '@features/TermsOfService';
import PrivacyPolicy from '@features/PrivacyPolicy';
import ConfirmEmail from '@features/ConfirmEmail';
import LoginWithToken from '@features/LoginWithToken';
import CreateUserFromPid from '@features/CreateUserFromPid';

const MyApp = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/terms-of-service" exact>
        <TermsOfService />
      </Route>
      <Route path="/privacy" exact>
        <PrivacyPolicy />
      </Route>
      <Route path="/sign-up">
        <SignUp />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <Route path="/dashboard">
        <ConfirmEmail>
          <LoginWithToken>
            <CreateUserFromPid>
              <Dashboard />
            </CreateUserFromPid>
          </LoginWithToken>
        </ConfirmEmail>
      </Route>
      <Redirect to="/"/>
    </Switch>
  )
}

function App () {
  const [analytics, setAnalytics] = useState(null);
  const [writeKey] = useState(process.env.REACT_APP_ANALYTICS_KEY);

  useEffect(() => {
    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({ writeKey });
      setAnalytics(response)
    }
    process.env.REACT_APP_ANALYTICS === 'true' && loadAnalytics()
  }, [writeKey])
  
  const theme = createTheme({
    palette: {
      primary: {
        main: '#79589F'
      }
    },
    typography: {
      fontFamily: 'BentonSans'
    },
  });

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AnalyticsProvider value={analytics}>
          <AxiosInstanceProvider config={{
            baseURL: process.env.REACT_APP_API,
            withCredentials: true,
          }}>
            <SnackbarProvider maxSnack={3}>
              <Identify />
              <PageViews />
              <WithLoader>
                <MyApp />
              </WithLoader>
              <GetUserOrProspect />
              <SubscriptionReadiness />
            </SnackbarProvider>
          </AxiosInstanceProvider>
        </AnalyticsProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
