import React, { useState, useEffect, useContext } from 'react';
import { connect } from "react-redux";
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import * as moment from 'moment';
import { useInterval } from '@hooks/useInterval';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
  ReferenceLine,
  BarChart,
  Bar
} from 'recharts';
import {AxiosContext} from '@context/AxiosContext';
import { useSnackbar } from 'notistack';
import NoTimeSeriesData from '@features/Dashboard/ScrapePanel/NoTimeSeriesData';

const TimeSeries = ({ accounts, dashLoading }) => {
  const {myAxios} = useContext(AxiosContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { username } = useParams();
  const target = accounts[username];
  const [data, setData] = useState(null);
  const [hasData, setHasData] = useState(null);
  const [chartData, setChartData] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await myAxios.post('/timeseries', {
        target_id: target._id
      });
      setData(data.series);
      setHasData(data.has_data);
    } catch(e) {
      enqueueSnackbar('Problem fetching bar chart of recent scans.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  useEffect(() => {
    username && fetchData();
  }, [username, dashLoading]);

  useEffect(() => {
    if (data) {
      const chart_data = data.map(d => {
        return {
          ...d,
          date: new Date(d.date).getTime()
        }
      })
      setChartData(chart_data);
    }
  }, [data])

  useInterval(() => {
    username && !dashLoading && fetchData();
  }, 1000 * process.env.REACT_APP_INTERVAL_TIMESERIES);

  if (dashLoading) {
    return null;
  }

  if (!hasData) {
    return (
      <NoTimeSeriesData target={target}/>
    )
  }

  return (
    <div style={{position: 'relative', width: '100%', paddingBottom: '80px'}}>
    <div style={{
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
    }}>
    <ResponsiveContainer width='100%' height={80} >
      <BarChart data={chartData} margin={{
        top: 0,
        right: 0,
        left: 0,
        bottom: 0
      }}>
        <XAxis
          dataKey="date"
          domain = {['auto', 'auto']}
          type="number"
          tick={false}
          height={0}
          scale="time"
        />
        <YAxis
          width={0}
          padding={{ top: 10 }}
        />
        <Tooltip
          formatter={(value, name, props) => [value, 'Scans']}
          labelFormatter={(unix) => moment(unix).format('h:mm a Do')}
        />
        <Bar dataKey="count" fill="#79589F" />
      </BarChart>
    </ResponsiveContainer>
    </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  accounts: state.dashboard.data_sources.byId,
  dashLoading: state.dashboard.loading,
  ...ownProps
});

export default connect(
  mapStateToProps,
  {}
)(TimeSeries);
