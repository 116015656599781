import { css } from '@emotion/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const LoadingCard = () => {

  function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const style = css`
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    margin-bottom: 14px;
    /* height: 100px; */
    /* border: 1px solid black; */
    .top {
      padding: 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      .name-skeleton {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .bottom {
      background-color: #f5f5f5;
      display: flex;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      .stat {
        .stat-title {
          white-space: nowrap;
          font-size: 10px;
          font-weight: 600;
          text-transform: uppercase;
          color: #9e9e9e;
        }
        .stat-val {
          color: #424242;
        }
      }
    }
  `;

  return (
    <div css={style}>
      <div className="top">
        <Skeleton circle={true} count={1} height={40} width={40} />
        <div className="name-skeleton">
          <Skeleton count={1} width={randomIntFromInterval(20,50)} />
        </div>
        <div className="other-skeleton">
          <Skeleton count={1} width={randomIntFromInterval(40,80)} />
        </div>
      </div>
    </div>
  )
}

const style = css`
  padding: 20px 20px;
  flex: 1;
  overflow: scroll;
`;

const ScrapePanelEmpty = () => {
  return (
    <div className="scrape-panel-empty" css={style}>
      <div className="columns">
        <div className="column is-one-third">
          {[...Array(10)].map((_, i) =>
            <LoadingCard key={i}/>
          )}
        </div>
        <div className="column is-two-thirds">
        </div>
      </div>
    </div>
  )
}

export default ScrapePanelEmpty;
