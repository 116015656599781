import React from 'react';
import ReactDOM from 'react-dom';
import './style/style.scss';
import App from './App';
import axios from 'axios';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './store';

const client = axios.create({
  baseURL: process.env.REACT_APP_API,
  responseType: 'json',
  withCredentials: true
});
const initialState = {};
const store = configureStore(initialState, client);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
