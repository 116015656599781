import React from 'react';
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import FullPageLoader from '@features/FullPageLoader';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function WithLoader({ user, children }) {
  const query = useQuery();
  return (
    <>
      { user === null || query.has('session_id') ? <FullPageLoader /> : children }
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  ...ownProps
});

export default connect(
  mapStateToProps,
  {},
)(WithLoader);
