import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { open_prices } from "@actions/dashboard";

const style = css`
  background: #d32f2f;
  color: white;
  flex: 0 0 3.25rem;
  font-family: 'BentonSans-Bold';
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
  a {
    margin-left: 5px;
    color: white;
    text-decoration: underline;
  }
`;

function ShopifyErrorBanner({ shopify_installed, shopify_subscription, provider }) {
  
  if (provider !== 'shopify') {
    return null;
  }
  
  if (!shopify_installed) {
    return <div css={style}>The app needs to be re-installed on the Shopify App Store</div>
  }

  if (shopify_subscription?.status === 'active') {
    return null;
  }

  return (
    <div css={style}>Your subcription is not longer active. Please select a new plan.</div>
  );
}

const mapStateToProps = (state) => ({
  shopify_subscription: state.user.shopify_subscription,
  shopify_installed: state.user.shopify_installed,
  provider:  state.user.provider,
});

export default connect(
  mapStateToProps
)(ShopifyErrorBanner);
