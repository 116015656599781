import { connect } from "react-redux";
import { get_user_or_prospect } from "@actions/user";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useInterval } from '@hooks/useInterval';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GetUserOrProspect = ({ get_user_or_prospect, user }) => {
  const query = useQuery();
  const pid = query.get('pid');

  useEffect(() => {
    get_user_or_prospect(pid);
  }, []);

  useInterval(() => {
    user && get_user_or_prospect(pid);
  }, 1000 * Number(process.env.REACT_APP_INTERVAL_USER_OR_PROSPECT));

  return null;
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  get_user_or_prospect
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GetUserOrProspect);
