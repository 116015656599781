import { css } from '@emotion/react';
import pp1 from '../../assets/pp1.jpeg';
import pp2 from '../../assets/pp2.jpeg';
import pp3 from '../../assets/pp3.jpeg';
import pp4 from '../../assets/pp4.jpeg';
import pp5 from '../../assets/pp5.jpeg';
import pp6 from '../../assets/pp6.jpeg';

const style = css`
  .title {
    font-family: BentonSans-Bold;
    text-align: center;
  }
  .subtitle {
    margin-bottom: 30px;
    font-family: BentonSans-Medium;
  }
  .love-card {
    padding: 30px 30px 20px;
    border-radius: 4px;
    background-color: #f0f4f6;
    .love-card-image {
      max-width: 60px;
    margin-bottom: 11px;
    border-radius: 60px;
    display: inline-block;
    }
    .love-card-name {
      line-height: 29px;
      font-size: 16px;
      font-family: BentonSans-Bold;
    }
    .love-card-p {
      color: #151429;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: -.005em;
      margin-bottom: 10px;
      margin-top: 10px;
      line-height: 28px;
      font-style: normal;
    }
  }
`

const TestimonialSection = () => {
  return (
    <section className="section love-section" css={style}>
      <div className="container is-max-desktop">
        <div className="columns is-centered">
          <div className="column has-text-centered">
            <h3 className="title is-2 is-size-3-mobile is-spaced">Thousands of Businesses are using this Audience Building Tool.</h3>
            <p className="subtitle is-5">Here are some kind words from a few of them.</p>
          </div>
        </div>
        <div className="tile is-ancestor">
          <div className="tile">
            <div className="tile is-parent is-vertical">
              <div className="love-card tile is-child">
                <img src={pp1} className="love-card-image" />
                <div className="love-card-name"><strong>@_shadj</strong></div>
                <p className="love-card-p">Lookalike Audience Maker has become essential to my workflow. Gave me a huge head start in building an audience. Would have taken a year to build the same list with our subscribe form.</p>
              </div>
              <div className="love-card tile is-child">
                <img src={pp4} className="love-card-image" />
                <div className="love-card-name"><strong>@thecodeboss</strong></div>
                <p className="love-card-p">LAM has got to be the easiest customer finding tool I have ever used. It gives us a massive advantage with our performance marketing.</p>
              </div>
            </div>
          </div>
          <div className="tile">
            <div className="tile is-parent is-vertical">
              <div className="love-card tile is-child">
                <img src={pp2} className="love-card-image" />
                <div className="love-card-name"><strong>@Breefield</strong></div>
                <p className="love-card-p">LAM is genius. It gives us targeted emails for our Facebook lookalike advertising campaigns. The UI makes it really easy to experiment with scanning different IG accounts and we like how there is no limit to the # of IG accounts you can scan.</p>
              </div>
              <div className="love-card tile is-child">
                <img src={pp5} className="love-card-image" />
                <div className="love-card-name"><strong>@julianwachholz</strong></div>
                <p className="love-card-p">Lookalike Audience Maker is probably the best tool I have started to use for our cold email outreach efforts. We've gotten very clever with which IG accounts we scan and have used this tool to validate customer interest in other markets.</p>
              </div>
            </div>
          </div>
          <div className="tile">
            <div className="tile is-parent is-vertical">
              <div className="love-card tile is-child">
                <img src={pp3} className="love-card-image" />
                <div className="love-card-name"><strong>@davejlong</strong></div>
                <p className="love-card-p">#LAM is a dream for finding customers that shop with my competitors!</p>
              </div>
              <div className="love-card tile is-child">
                <img src={pp6} className="love-card-image" />
                <div className="love-card-name"><strong>@Botto</strong></div>
                <p className="love-card-p">holy crap. LAM is awesome, no more guessing if an email list will be interested in our product. If you're not using this tool your missing out! </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestimonialSection;