import { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

const UnknownIgAccount = ({ data_sources, dashLoading }) => {
  let { datasource } = useParams();
  const data = data_sources.byId[datasource];
  if (!dashLoading && !data) {
    return <Redirect to={`/dashboard/${data_sources.allIds[0]}`} />
  } else {
    return null;
  }
}

const mapStateToProps = (state) => ({
  data_sources: state.dashboard.data_sources,
  dashLoading: state.dashboard.loading
});

export default connect(
  mapStateToProps,
  {}
)(UnknownIgAccount);
