import React from 'react';
import { connect } from "react-redux";
import Header from '@features/Dashboard/ScrapePanel/Header';
import Summary from '@features/Dashboard/ScrapePanel/Summary';
import Data from '@features/Dashboard/ScrapePanel/Data';
import { css } from '@emotion/react';
import { open_add_emails_drawer } from "@actions/dashboard";
import AddAudienceDrawer from '@features/Dashboard/ScrapePanel/AddAudienceDrawer';
import Audiences from '@features/Dashboard/ScrapePanel/Audiences'
import { useParams } from 'react-router-dom';
import { AudienceProvider } from '@context/AudienceContext'

const style = css`
  padding: 20px 20px;
  flex: 1;
  overflow: scroll;
`;

const dataCard = css`
  border: 1px solid #e7ebf3;
  border-radius: 4px;
  background: white;
  .header {
    border-bottom: 1px solid #e7ebf3;
    padding: 15px;
    font-family: 'BentonSans-Medium';
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

`

const ScrapePanel = () => {
  
  return (
    <AudienceProvider>
      <Header/>
      <div id="data-block" css={style}>
        <Audiences />
      </div>
      <AddAudienceDrawer />
    </AudienceProvider>
  )
}

export default ScrapePanel;
