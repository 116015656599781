import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { open_prices } from "@actions/dashboard";

const style = css`
  background: #d32f2f;
  color: white;
  flex: 0 0 3.25rem;
  font-family: 'BentonSans-Bold';
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
  a {
    margin-left: 5px;
    color: white;
    text-decoration: underline;
  }
`;

function SubscriptionPastDueBanner({ stripe_subscription_status, stripe_hosted_invoice_url }) {

  if (stripe_subscription_status !== 'past_due') {
    return null;
  }

  return (
    <div css={style}><span>Your subscription payment is past due.&nbsp;<a href={stripe_hosted_invoice_url} target="_blank">Click here to pay your invoice.</a></span></div>
  );
}

const mapStateToProps = (state) => ({
  stripe_subscription_status: state.user.stripe_subscription_status,
  stripe_hosted_invoice_url:  state.user.stripe_hosted_invoice_url
});

export default connect(
  mapStateToProps
)(SubscriptionPastDueBanner);
