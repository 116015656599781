import { useRef, useEffect, useState } from 'react';
import { css } from '@emotion/react';

const style = css`
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  .step-website {
    width: 200px;
    background-color: white;
    padding: 10px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .step-api {
    width: 150px;
    background-color: white;
    padding: 10px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .step-table {
    /* background-color: yellow; */
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 12px;
    .table {
      border-radius: 6px;
      width: 170px;
    }
  }
  .annotation {
    background-color: #f06292;
    color: white;
    font-family: BentonSans-Medium;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    padding: 5px;
    width: 260px;
    left: 54%;
    /* transform: translateX(-50%); */
    top: 188px;
    font-size: 12px;
    display: block;
    @media (max-width: 1040px) {
      display: none;
    }
  }
  .annotation-small {
    white-space: nowrap;
    background-color: #f06292;
    color: white;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    padding: 5px;
    left: 54%;
    /* transform: translateX(-50%); */
    top: 187px;
    font-size: 12px;
    display: none;
    @media (max-width: 1040px) {
      display: block;
    }
  }
  svg {
    &#chart {
      background: lightgray;
      line {
        stroke: red;
        stroke-width:1
      }
    }
  }
`

const dot = {
  sprite: null,
  track: null,

  // Initialize the dot: connect sprite and track properties with supplied SVG elements
  init: function(sprite, track) {
    // this.sprite = document.getElementById(sprite);
    // this.track = document.getElementById(track);
    this.sprite = sprite.current;
    this.track = track.current;
  },

  // Put the dot on its spot
  move: function(u) {
    const p = this.track.getPointAtLength(u * this.track.getTotalLength());
    this.sprite.setAttribute("transform", `translate(${p.x}, ${p.y})`);
  }
};

const anim = {
  isAnimating: true,
  cb: null,

  start: function(duration, cb) {
    this.cb = cb;
    this.isAnimating = true;
    this.duration = duration;
    this.tZero = Date.now();

    requestAnimationFrame(() => this.run());
  },

  stop: function() {
    this.isAnimating = false;
  },

  run: function() {
    if (!this.isAnimating) {
      return;
    }

    let u = Math.min((Date.now() - this.tZero) / this.duration, 1);

    if (u < 1) {
        // Keep requesting frames, till animation is ready
        requestAnimationFrame(() => this.run());
    } else {
        this.onFinish();
    }


    dot.move(u);
  },

  onFinish: function() {
    setTimeout(() => this.start(this.duration, this.cb), 0);
    this.cb();
  }
};

const emails = [
  ['amaranth@yahoo.ca', 'cderoove@live.com', 'chrisj@yahoo.ca'],
  ['parksh@gmail.com', 'tellis@comcast.net', 'benits@icloud.com'],
  ['kevinm@msn.com', 'jeffcovey@yahoo.com', 'novanet@yahoo.com']
]

const Animation = () => {
  const sprite = useRef(null);
  const track = useRef(null);

  const [emailIndex, setEmailIndex] = useState(0);

  useEffect(() => {
    dot.init(sprite, track);
    anim.start(1000, () => {
      const newEmailIndex = emailIndex + 1 >= emails.length ? 0 : emailIndex + 1;
      setEmailIndex(newEmailIndex)
    });
  }, [emailIndex])

  useEffect(() => {
    //do cleanup
    return () => {
      anim.stop();
    }
  }, []);

  return (
    <div css={style}>
      <div className="step-website">
        Enter Any Instagram Or TikTok Username
      </div>
      <svg id="line-1" width="50" height="60">
        <line x1="25" y1="0" x2="25" y2="60" stroke="black" strokeDasharray="4" strokeWidth="2"></line>
      </svg>
      <div className="step-api">
        Our App
      </div>
      <svg id="loader-track" width="50" height="60">
        <line x1="25" y1="0" x2="25" y2="60" stroke="black" strokeDasharray="4" strokeWidth="2" ref={track}></line>
        <circle
         r="6"
         cy="0"
         cx="0"
         ref={sprite}
         style={{
           'fill': '#FA8080',
           'opacity': 1,
           'transition': 'opacity .10s ease-in'
         }}
        />
      </svg>
      <div className="step-table">
        <table className="table">
          <tbody>
            <tr><td>
                  {emails[emailIndex][0]}
            </td></tr>
            <tr><td>{emails[emailIndex][1]}</td></tr>
            <tr><td>{emails[emailIndex][2]}</td></tr>
            <tr><td>...thousands more</td></tr>
          </tbody>
        </table>
      </div>
      <div className="annotation">
      Emails for your Lookalike Audience
      </div>
      <div className="annotation-small">
      Build Your Audience
      </div>
    </div>
  )
}

export default Animation;
