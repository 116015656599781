import { connect } from "react-redux";
import { login_with_token } from "@actions/user";
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { css } from '@emotion/react';

const style = css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'BentonSans-Bold';
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LoginWithToken = ({ login_with_token, children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const query = useQuery();
  const history = useHistory();
  const token = query.get('token');
  const [done, setDone] = useState(false);

  const handleLogin = async (token) => {
    try {
      setDone(false);
      await login_with_token(token);
      setDone(true);
    } catch(e) {
      setDone(true);
      enqueueSnackbar(e.error?.response?.data?.message || 'Problem logging in. Please try again.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  useEffect(() => {
    token && handleLogin(token);
  }, [token]);

  useEffect(() => {
    if (done) {
      //TODO add pause here to try to recreate login with token issue seen in mouseflow.
      query.delete('token');
      history.replace({
        search: query.toString()
      });
    }
  }, [done])

  if (!query.has('token')) {
    return children;
  }

  return (
    <div css={style}>Logging in user with token...</div>
  );
}

const mapStateToProps = (_, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = {
  login_with_token
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginWithToken);
