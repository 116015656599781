import { css } from '@emotion/react';
import { HashLink } from 'react-router-hash-link';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

const style = css`
  margin-bottom: 40px;
  .title {
    // font-size: 53px;
    font-family: BentonSans-Bold;
    word-spacing: 3px;
    // line-height: 53px;
    &.powerful {
      text-decoration-skip-ink: none;
      border-bottom: 0 solid #fa8080;
      text-decoration: underline;
      text-decoration-color: #FA8080;
    }
    .powerful {
      text-decoration-skip-ink: none;
      border-bottom: 0 solid #fa8080;
      text-decoration: underline;
      text-decoration-color: #FA8080;
    }
  }
  .description {
    margin-top: 40px;
    margin-bottom: 50px;
    font-size: 18px;
    letter-spacing: 1.2px;
    font-family: BentonSans-Medium;
    .powerful {
      text-decoration-skip-ink: none;
      border-bottom: 0 solid #fa8080;
      text-decoration: underline;
      text-decoration-color: #FA8080;
    }
  }
  .button-wrap {
    display: flex;
    align-items: center;
    .button {
      margin-right: 10px;
    }
    div {
      font-size: 12px;
    }
  }
  .no-cc {
    margin-top: 10px;
    font-size: 12px;
  }
`

const Selling = ({ user }) => {
  return (
    <div css={style}>
      <h1 className="title is-1 is-size-2-mobile">Their Followers.<br />Your Audience.</h1>
      <div className="description">Make perfect Facebook (or Google) lookalike audiences based on any Instagram or TikTok account's followers. Any niche, any target market - we have your emails addresses.</div>
      <div className="button-wrap">
        { user && 
          <Link
            to="/dashboard"
            className="button is-large is-primary"
          >
          Go To Your Dashboard
          </Link>
        }
        { !user &&
          <a
            href="https://apps.shopify.com/lookalike-audience-maker"
            className="button is-large is-primary"
          >
            Try for Free
          </a>
        }
      </div>
      <div className="no-cc">No credit card required</div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(
  mapStateToProps
)(Selling)