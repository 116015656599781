export function get_quick_dash() {
  return {
    type: 'GET_QUICK_DASH',
    payload: {
      request: {
        url: '/quick_dash',
      }
    }
  }
}

export function get_scrape_data(username) {
  return {
    type: 'GET_SCRAPE_DATA',
    payload: {
      request: {
        method: 'post',
        url: '/get_scrape_data',
        data: { username }
      }
    }
  }
}

// export function add_ig_username(form) {
//   return {
//     type: 'ADD_IG_USERNAME',
//     payload: {
//       request: {
//         method: 'post',
//         url: '/add_ig_username',
//         data: form
//       }
//     }
//   }
// }

export function create_data_order(form) {
  return {
    type: 'CREATE_DATA_ORDER',
    payload: {
      request: {
        method: 'post',
        url: '/create_data_order',
        data: form
      }
    }
  }
}

export function change_target_status(form) {
  return {
    type: 'CHANGE_TARGET_STATUS',
    payload: {
      request: {
        method: 'post',
        url: '/change_target_status',
        data: form
      }
    }
  }
}

export function request_more_emails(form) {
  return {
    type: 'REQUEST_MORE_EMAILS',
    payload: {
      request: {
        method: 'post',
        url: '/more_emails',
        data: form
      }
    }
  }
}

export function close_prices() {
  return (dispatch) => {
    return new Promise(function(resolve, reject) {
      dispatch({
        type: 'CLOSE_PRICES'
      });
      resolve();
    });
  }
}

export function open_prices() {
  return (dispatch) => {
    return new Promise(function(resolve, reject) {
      dispatch({
        type: 'OPEN_PRICES'
      });
      resolve();
    });
  }
}

export function close_add_emails_drawer() {
  return (dispatch) => {
    return new Promise(function(resolve, reject) {
      dispatch({
        type: 'CLOSE_EMAIL_DRAWER'
      });
      resolve();
    });
  }
}

export function open_add_emails_drawer() {
  return (dispatch) => {
    return new Promise(function(resolve, reject) {
      dispatch({
        type: 'OPEN_EMAIL_DRAWER'
      });
      resolve();
    });
  }
}
