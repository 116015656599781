import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import LoadingSummary from '@features/Dashboard/ScrapePanel/LoadingSummary';

const style = css`
  box-shadow: 0 0 0 1px rgb(89 105 129 / 10%), 0 1px 3px 0 rgb(89 105 129 / 10%), 0 1px 2px 0 rgb(0 0 0 / 5%);
  border-radius: 4px;
  background: white;
  .data-card__header {
    border-bottom: 1px solid #e7ebf3;
    padding: 15px;
    font-family: 'BentonSans-Medium';
    .data-card__title {
      color: #212121;
    }
  }
  .data-card__body {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .data-card__body__item {
      flex: 1;
      padding: 40px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      &:not(:last-child) {
        border-right: 1px solid #e7ebf3;
        @media (max-width: 768px) {
          border-right: none;
          border-bottom: 1px solid #e7ebf3;
        }
      }
      .data-card__body__item__value {
        color: #212121;
        font-size: 24px;
        font-family: 'BentonSans-Bold';
        &.new {
          color: #79589F;
        }
      }
      .data-card__body__item__label {
        text-transform: uppercase;
        font-size: 12px;
        font-family: 'BentonSans-Medium';
        color: #3F3F44;
        &.new {
          font-family: 'BentonSans-Bold';
        }
      }
    }
  }
`;

const Summary = ({ accounts, dashLoading }) => {
  let { username } = useParams();
  const data = accounts[username];

  if (dashLoading) {
    return (
      <LoadingSummary />
    )
  }

  if (!data) {
    return null;
  }

  return (
    <div className="columns">
      <div className="column is-full-tablet is-two-thirds-desktop">
        <div css={style} className="data-card">
          <div className="data-card__header">
            <div className="data-card__title">
            Summary
            </div>
          </div>
          <div className="data-card__body">
            <div className="data-card__body__item">
              <div className="data-card__body__item__value">
              {data.total_scrapes || 0}
              </div>
              <div className="data-card__body__item__label">
              Total Users Scanned
              </div>
              { data.last_download_at &&
                <div style={{ 'margin-top': '20px' }}>
                  <div className="data-card__body__item__value new">
                  +{data.new_scrapes}
                  </div>
                  <div className="data-card__body__item__label new">
                  Since Last Download
                  </div>
                </div>
              }
            </div>
            <div className="data-card__body__item">
              <div className="data-card__body__item__value">
              {data.total_emails || 0}
              </div>
              <div className="data-card__body__item__label">
              Emails Found
              </div>
              { data.last_download_at &&
                <div style={{ 'margin-top': '20px' }}>
                  <div className="data-card__body__item__value new">
                  +{data.new_emails}
                  </div>
                  <div className="data-card__body__item__label new">
                  Since Last Download
                  </div>
                </div>
              }
            </div>
            <div className="data-card__body__item">
              <div className="data-card__body__item__value">
              {data.total_phones || 0}
              </div>
              <div className="data-card__body__item__label">
              Phone #s Found
              </div>
              { data.last_download_at &&
                <div style={{ 'margin-top': '20px' }}>
                  <div className="data-card__body__item__value new">
                  +{data.new_phones}
                  </div>
                  <div className="data-card__body__item__label new">
                  Since Last Download
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  accounts: state.dashboard.data_sources.byId,
  dashLoading: state.dashboard.loading
});

export default connect(
  mapStateToProps,
  {}
)(Summary);
