import { css } from '@emotion/react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

const style = css`
  background-image: linear-gradient(to bottom,#654a86,#534292);
  background-color: #534292;
  .title {
    color: white;
    text-align: center;
    font-family: BentonSans-Bold;
  }
  .subtitle {
    color: white;
    text-align: center;
    margin-bottom: 30px;
    font-family: BentonSans-Medium;
  }
  .myshopify {
    color: white;
    font-size: 26px;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 20px;
    }
    .store {
      font-weight: 600;
      border-bottom: 2px solid #a9a0c8;
      padding-bottom: 2px;
      color: #a9a0c8;
    }
    .domain {
      font-weight: 600;
      margin-left: 5px;
    }
  }
  .shopify-cta {
    font-family: 'BentonSans-Medium';
    margin-top: 60px;
  }
`;

const ShopifySection = ({ user }) => {
  return (
    <section className="section" id="trial" css={style}>
      <div className="container is-max-desktop">
        <div className="columns is-mobile is-centered">
          <div className="column has-text-centered">
            <h1 className="title is-2 is-size-3-mobile is-spaced">Are you ready to...</h1>
            <div className="subtitle is-5">Take your store's performance marketing to the next level?</div>
          </div>
        </div>
        <div className="myshopify">
          <span className="store">your-store-domain&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span className="domain">.myshopify.com</span>
        </div>
        <div className="columns is-mobile is-centered">
        <div className="column has-text-centered">
          <div className="shopify-cta">
            { user &&
              <Link
                to="/dashboard"
                className="button is-large"
              >
              Go To Your Dashboard
              </Link>
            }
            { !user && 
              <a
                href="https://apps.shopify.com/lookalike-audience-maker"
                className="button is-large"
              >
                Try for Free
              </a>
            }
          </div>
        </div>
        </div>


      </div>
    </section>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(
  mapStateToProps
)(ShopifySection)
