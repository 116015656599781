import React, { useState, useContext } from 'react';
import { connect } from "react-redux";
import { Drawer, Box, Tooltip } from '@mui/material';
import { css } from '@emotion/react';
import { GrClose } from 'react-icons/gr';
import { useSnackbar } from 'notistack';
import {AxiosContext} from '@context/AxiosContext';
import IgSearchResultCard from '@features/Dashboard/IgSearchResultCard';
import RadioButton from '@features/RadioButton';
import { create_data_order } from "@actions/dashboard";
import { useHistory } from 'react-router-dom';
import capitalize from "lodash/capitalize";


// const mobileMarks = [
//   {
//     value: 400,
//     label: '400',
//   },
//   {
//     value: 600,
//     label: '600',
//   },
//   {
//     value: 800,
//     label: '800',
//   },
// ]


const style = css`
  .drawer-header {
    background: #f7f8fb;
    padding: 12px 20px 10px;
    min-height: 63px;
    border-bottom: 1px solid #e7ebf3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .drawer-header-text {
      color: #323b49;
      font-size: 15px;
    }
    .drawer-header-close {
      display: flex;
      cursor: pointer;
    }
  }
  .drawer-body {
    display: flex;
    justify-content: center;
    padding: 50px 0;
    .drawer-body-content {
      max-width: 800px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      .form-info {
        .info-title {
          font-size: 15px;
          color: #79589f;
        }
        .info-content {
          font-size: 13px;
          margin-top: 10px;
          color: #475366;
          line-height: 1.5;
        }
      }
      .form {
        .label {
          color: #62748e;
          font-size: 12px;
          font-weight: 'BentonSans-Bold';
        }
        input {
          height: 32px;
        }
        .search-wrapper {
          margin-top: auto;
          button {
            font-family: 'BentonSans-Medium';
            font-size: 13px;
            line-height: 22px;
            height: 32px;
          }
        }
      }
      .separator {
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #eef1f6;
      }
      .submit-form {
        button {
          font-family: 'BentonSans-Medium';
          font-size: 13px;
          line-height: 22px;
          height: 32px;
        }
      }
    }
  }
`;

const AddIgAccountDrawer = ({
  dash_loading,
  has_data_sources,
  // add_ig_username,
  create_data_order,
  ...ownProps
}) => {
  const history = useHistory();
  const myAxios = useContext(AxiosContext);
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({ 
    platform: 'instagram',
    username: '',
    audience_size: 500
  });
  const [searchData, setSearchData] = useState(null);
  const [searching, setSearching] = useState(false);
  const [submitting, setSubmitting] = useState(false);
    
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setSearching(true);
      // const { data } = await myAxios.post('/search_ig_username', { username: formData.username });
      const { data } = await myAxios.post('/search_username', { 
        platform: formData.platform,
        username: formData.username
      })
      setSearchData(data);
      setSearching(false);
    } catch(e) {
      console.log(e)
      setSearching(false);
      enqueueSnackbar(e?.response?.data || 'Problem searching account. Please try again.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setSubmitting(true);
      const order = await create_data_order(formData)
      const { _id: data_source_id } = order.payload.data
      setSubmitting(false);
      ownProps.setDrawerOpen(false);
      history.push(`/dashboard/${data_source_id}`)
      setFormData({ 
        platform: 'instagram',
        username: '',
        audience_size: 500 
      });
      setSearchData(null);
    } catch(e) {
      setSubmitting(false);
      enqueueSnackbar(e.error?.response?.data || 'Problem submitting request. Please try again.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  return (
    <>
      <Drawer
        anchor='bottom'
        open={!dash_loading && (!has_data_sources || ownProps.drawerOpen)}
        onClose={() => has_data_sources && ownProps.setDrawerOpen(false) }
      >
        <Box
          sx={{ width: 'auto' }}
          role="presentation"
        >
          <div css={style} className="drawer">
            <div className="drawer-header">
              <div className="empty"></div>
              <div className="drawer-header-text">
              { has_data_sources ? "Let's Make Another Lookalike Audience" : "Welcome! Let's Make a Lookalike Audience."}
              </div>
              { has_data_sources &&
                <div
                  className="drawer-header-close"
                  onClick={() => ownProps.setDrawerOpen(false)}
                >
                  <GrClose />
                </div>
              }
              { !has_data_sources &&
                <div className="empty"></div>
              }
            </div>
            <div className="drawer-body">
              <div className="drawer-body-content">
                <div className="columns">
                  <div className="column is-one-third">
                    <div className="form-info">
                      <div className="info-title">
                      {`Step 1: ${ has_data_sources ? 'Add Another Data Source' : 'Add Your Data Source' }`}
                      </div>
                      <div className="info-content">
                      Ask yourself, "Where do my Shopify customers hangout on Instagram or TikTok?"
                      </div>
                    </div>
                  </div>
                  <div className="column is-two-thirds">
                    <form className="form">
                    <label className="label">Select Platform</label>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2
                    }}>
                      <Box mr={1}>
                      <RadioButton
                        name="source"
                        id="instagram"
                        value="instagram"
                        isChecked={formData.platform === "instagram"}
                        handleChange={() => {
                          setSearchData(null)
                          setFormData({ 
                            ...formData,
                            platform: 'instagram'
                          })
                        }}
                      >
                        Instagram
                      </RadioButton>
                      </Box>
                      {/*<Tooltip 
                        title='Coming July 11th'
                        placement="top"
                      >*/}
                      <Box ml={1}>
                      <RadioButton
                        name="source"
                        id="tiktok"
                        value="tiktok"
                        isChecked={formData.platform === "tiktok"}
                        handleChange={() => {
                          setSearchData(null)
                          setFormData({ 
                            ...formData,
                            platform: 'tiktok'
                          })
                        }}
                      >
                        TikTok
                      </RadioButton>
                      </Box>
                      {/*</Tooltip>*/}
                    </Box>
                    </form>
                    {/* SEARCH FORM */}
                    {searchData &&
                      <IgSearchResultCard setSearchData={setSearchData} {...searchData} />
                    }
                    {!searchData &&
                      <form className="form" onSubmit={handleSearch}>
                        <div className="columns is-1 is-variable">
                          <div className="column">
                            <div className="field">
                              <label className="label">Username</label>
                              <div className="control">
                                <input
                                  autoComplete="off"
                                  autoFocus
                                  className="input is-normal"
                                  type="text"
                                  placeholder="ex: kyliejenner"
                                  name="username"
                                  required
                                  value={formData.username}
                                  onChange={e => setFormData({
                                    ...formData,
                                    username: e.target.value
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="column search-wrapper">
                          <button type="submit" className="button is-primary is-medium">{searching ? 'Searching Account...' : `Find it on ${capitalize(formData.platform)}`}</button>
                          </div>
                        </div>
                      </form>
                    }
                  </div>
                </div>
                <div className="separator"></div>
                <div className="columns">
                  <div className="column is-one-third">
                    <div className="form-info">
                      <div className="info-title">
                      Step 2: Choose Audience Size
                      </div>
                      <div className="info-content">
                      This determines how many email addresses we find for you of individuals who directly follow {searchData?.username || 'the data source' } or have interests similar to the followers of {searchData?.username || 'the data source' }.
                      </div>
                    </div>
                  </div>
                  <div className="column is-two-thirds">
                    <form className="form">
                      <label className="label">Select How Many Emails You Want</label>
                      <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        flexWrap: 'wrap'
                      }}>
                        <RadioButton
                          name="aud_size"
                          id="100_emails"
                          value={100}
                          isChecked={formData.audience_size === 100}
                          handleChange={() => setFormData({
                            ...formData,
                            audience_size: 100
                          })}
                          small
                          disabled={!searchData?.username}
                        >
                          100 Emails
                        </RadioButton>                      
                        <RadioButton
                          name="aud_size"
                          id="200_emails"
                          value={200}
                          isChecked={formData.audience_size === 200}
                          handleChange={() => setFormData({
                            ...formData,
                            audience_size: 200
                          })}
                          small
                          disabled={!searchData?.username}
                        >
                          200 Emails
                        </RadioButton> 
                        <RadioButton
                          name="aud_size"
                          id="500_emails"
                          value={500}
                          isChecked={formData.audience_size === 500}
                          handleChange={() => setFormData({
                            ...formData,
                            audience_size: 500
                          })}
                          small
                          disabled={!searchData?.username}
                        >
                          500 Emails
                        </RadioButton> 
                      </Box>
                      {/*
                      <Box sx={{
                        px: { xs: 1.5, md: 0 }
                      }}>
                        <Slider
                          sx={{
                            '& .MuiSlider-markLabel': {
                              fontSize: '12px'
                            },
                          }}
                          aria-label="Always visible"
                          valueLabelDisplay={searchData ? 'on' : 'off'}
                          
                          defaultValue={600}
                          getAriaValueText={valuetext}
                          step={200}
                          marks={marks}
                          min={200}
                          max={1000}
                          disabled={!searchData}
                        />
                      </Box>
                      */}
                    </form>
                  </div>
                </div>
                <div className="separator"></div>
                <div className="columns">
                  <div className="column is-one-third">
                    <div className="form-info">
                      <div className="info-title">
                      Step 3: Submit For Processing
                      </div>
                      <div className="info-content">
                      After you submit, we will begin building your lookalike audience. It will take us a few hours to scan the account.
                      </div>
                    </div>
                  </div>
                  <div className="column is-two-thirds">
                  {/* END SEARCH FORM */}
                  {/* SUBMIT FORM */}
                  <form className="submit-form" onSubmit={handleSubmit}>
                    <button
                      disabled={!searchData}
                      type="submit"
                      className="button is-primary is-medium"
                    >
                    {submitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  dash_loading:    state.dashboard.loading,
  has_data_sources: state.dashboard.data_sources.allIds.length > 0,
  ...ownProps
});

// const mapDispatchToProps = {
//   add_ig_username: add_ig_username
// };

const mapDispatchToProps = {
  create_data_order: create_data_order
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddIgAccountDrawer);
