import React, { useState, useEffect, useContext } from 'react';
import { css } from '@emotion/react';
import { useSnackbar } from 'notistack';
import { connect } from "react-redux";
import {AxiosContext} from '@context/AxiosContext';
import { shopify_change_plan } from "@actions/shopify";

const style = css`
  .plan-title {
    margin-bottom: 12px;
    font-family: 'BentonSans-Medium';
  }
  .amount-wrapper {
     display: flex;
     align-items: center;
     h3 {
       margin-bottom: 0px;
       font-family: 'BentonSans-Bold';
     }
     .amount-slash {
       margin-right: 5px;
       margin-left: 5px;
     }
  }
  .plan-perks {
    margin-top: 20px;
    .perk {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 10px;
      .checkmark {
        font-size: 20px;
        color: #3161fc;
      }
      .text {
        padding-left: 10px;
        .powerful {
          border-bottom: 3px solid #fa8080;
        }
        &.small {
          font-size: 14px;
        }
      }
    }
  }
  .choose-plan-button {
    margin-top: 30px;
    font-family: 'BentonSans-Bold';
  }
`;

const ShopifyPriceCard = ({ shopify_change_plan, plan_name, is_trial, subscription_active, ...p }) => {
  const {myAxios} = useContext(AxiosContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [creatingCheckoutSession, setCreatingCheckoutSession] = useState(false);
    
  const handlePlanSelect = async (plan) => {
    try {
      setCreatingCheckoutSession(true);
      const { payload: { data: { billing_url }}} = await shopify_change_plan(plan);
      window.location.href = billing_url;
    } catch(e) {
      console.log(e)
      enqueueSnackbar(e?.error?.response?.data || 'Problem selecting the plan. Try refreshing the page', {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    } finally {
      setCreatingCheckoutSession(false)
    }
  }

  return (
    <div css={style} className="card">
      <div className="card-content">
        <div className="content">
          <h2 className="plan-title title is-5">{p.name}</h2>
          <div className="amount-wrapper">
            <h3 className="title is-2">${p.price}</h3>
            <div><span className="amount-slash">/</span>Month</div>
          </div>
          <div className="plan-perks">
            <div className="perk">
              <div className="text">
                <span className="powerful">{p.email_quota.toLocaleString()}</span> emails / month
              </div>
            </div>
            <div className="perk">
              <div className="text small">
                <span>${p.add_more.rate}</span> per additional email
              </div>
            </div>
          </div>
          <button
            onClick={() => handlePlanSelect(p.id)}
            className="choose-plan-button button is-fullwidth is-medium is-primary"
            disabled={
              (p.name === plan_name && /*!is_trial &&*/ subscription_active) || 
              creatingCheckoutSession
            }
          >
          {
            p.name === plan_name && /*!is_trial &&*/ subscription_active ? 
              (is_trial ? <span style={{ fontSize: '14px' }}>Currently on Free Trial</span> : 'Current Plan') : (creatingCheckoutSession ? 
                'Loading...' : 
                'Choose Plan' 
            )
          }
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  is_trial: state.user.is_trial,
  plan_name: state.user?.shopify_subscription?.name,
  subscription_active: state.user?.shopify_subscription?.status === 'active',
  ...ownProps
});

const mapDispatchToProps = {
  shopify_change_plan
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopifyPriceCard);
