import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import { Box, Skeleton } from '@mui/material'
import { useDetectAdBlock } from "adblock-detect-react";
import axios from 'axios';
import { useSnackbar } from 'notistack';

import StatusBadge from "@features/Dashboard/StatusBadge";
import StatusHelpText from "@features/Dashboard/StatusHelpText";
import MoreEmailsDrawer from '@features/Dashboard/ScrapePanel/ChangeTaskStatusDrawer';

var FileDownload = require('js-file-download');
var contentDisposition = require('content-disposition');

const style = css`
  flex: 0 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #e7ebf3;
  background-color: white;
  .left {
    display: flex;
    .picture {
      display: flex;
      align-items: center;
      img {
        width: auto;
        height: 40px;
        min-width: 40px;
        border-radius: 50%;
      }
    }
    .info-wrapper {
      margin-left: 10px;
      display: flex;
      .info-top {
        display: flex;
        align-items: center;
        .name {
          color: #212121;
          font-size: 14px;
          font-family: 'BentonSans-Medium';
        }
        .status {
          margin-left: 10px;
        }
      }
      .info-bottom {
        display: flex;
        margin-left: 10px;
        align-items: center;
        font-size: 13px;
        color: #757575;
      }
    }
  }
  .right {

  }
`;

const ScrapePanelHeader = ({ accounts, dashLoading, open_add_emails_drawer }) => {
  let { datasource } = useParams();
  const data = accounts[datasource];
  const adBlockDetected = useDetectAdBlock();
  const { enqueueSnackbar } = useSnackbar();
  const [downloadingDatasource, setDownloadingDatasource] = useState(false)
  const [downloadingAllData, setDownloadingAllData] = useState(false)
  
  const handleDownloadDatasource = () => {
    if (adBlockDetected) {
      enqueueSnackbar(`If download is not starting, you may need to turn off your ad block extension.`, {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
    setDownloadingDatasource(true)
    axios({
      url: `${process.env.REACT_APP_API}/download_datasource/${datasource}`,
      method: 'GET',
      responseType: 'blob',
      withCredentials: true
    }).then((response) => {
      var { parameters } = contentDisposition.parse(response.headers['content-disposition']);
      FileDownload(response.data, parameters.filename);
      enqueueSnackbar(`Your CSV file has been downloaded.`, {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }).catch(async e => {
      try {
        const message = JSON.parse(await e.response.data.text())
        if (!message) {
          throw new Error()
        }
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom'
          }
        });
      } catch(e) {
        enqueueSnackbar('Problem downloading file, please try again.', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom'
          }
        });
      }
    }).finally(() => {
      setDownloadingDatasource(false)
    });
  }
  
  const handleDownloadAllData = () => {
    if (adBlockDetected) {
      enqueueSnackbar(`If download is not starting, you may need to turn off your ad block extension.`, {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
    setDownloadingAllData(true)
    axios({
      url: `${process.env.REACT_APP_API}/download_all_data`,
      method: 'GET',
      responseType: 'blob',
      withCredentials: true
    }).then((response) => {
      var { parameters } = contentDisposition.parse(response.headers['content-disposition']);
      FileDownload(response.data, parameters.filename);
      enqueueSnackbar(`Your CSV file has been downloaded.`, {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }).catch(async e => {
      try {
        const message = JSON.parse(await e.response.data.text())
        if (!message) {
          throw new Error()
        }
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom'
          }
        });
      } catch(e) {
        enqueueSnackbar('Problem downloading file, please try again.', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom'
          }
        });
      }
    }).finally(() => {
      setDownloadingAllData(false)
    });
  }
  
  if (dashLoading) {
    return (
      <div css={style}>
        <Skeleton variant="rounded" width={100} height={30} />
      </div>
    )
  }

  if (!data) {
    return (
      <div css={style}>
        <Skeleton variant="rounded" width={100} height={30} />
      </div>
    )
  }

  return (
    <>
      <div css={style}>
        <div className="left">
          { data.pic_string &&
            <div className="picture">
              <img src={data.pic_string} />
            </div>
          }
          <div className="info-wrapper">
            <div className="info-top">
              <div className="name is-hidden-mobile">
              {data.datasource}
              </div>
              {/*<div className="status">
                <StatusBadge status={data.status} />
              </div>*/}
            </div>
            {/* <div className="info-bottom is-hidden-touch">
              <StatusHelpText status={data.status} />
            </div> */}
          </div>
        </div>
        <div className="right">
          <div class="dropdown is-hoverable is-right">
            <div class="dropdown-trigger">
              <button class="button is-small" aria-haspopup="true" aria-controls="dropdown-menu2">
                <span>More Download Options</span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu2" role="menu">
              <div class="dropdown-content">
                <a href="#" class="dropdown-item" onClick={handleDownloadDatasource}>
                  { downloadingDatasource ?
                    <p>Combining CSVs for <strong>{data.username}...</strong></p>
                    :
                    <p>Combine CSVs for <strong>{data.username}</strong></p>

                  }
                </a>
                <hr class="dropdown-divider" />
                <a href="#" class="dropdown-item" onClick={handleDownloadAllData}>
                  { downloadingAllData ?
                    <p>Combining CSVs for All Datasources...</p>
                    :
                    <p>Combine CSVs for All Datasources</p>
                  }
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = (state) => ({
  accounts: state.dashboard.data_sources.byId,
  dashLoading: state.dashboard.loading
});

export default connect(
  mapStateToProps,
  {}
)(ScrapePanelHeader);
