import React, { createContext, useReducer } from 'react';

const AudienceContext = createContext(null);
const { Provider } = AudienceContext;

function audienceReducer(state, action) {
  switch(action.type) {
    case 'get':
      return [...action.data];
    case 'add':
      return [action.data, ...state];
    default:
      return state;
  }
}

const AudienceProvider = ({
  children,
}) => {
  const [audiences, setAudiences] = useReducer(audienceReducer, [])

  return (
    <Provider value={{
      audiences,
      setAudiences
    }}>
      {children}
    </Provider>
  );
};

export {AudienceContext, AudienceProvider};
