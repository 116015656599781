import Animation from './Animation';
import Selling from './Selling';
import CompanyList from './CompanyList';
import { css } from '@emotion/react';

const style = css`
  padding-top: 120px;
`;

const IntroSection = () => {
  return (
    <div css={style}>
      <section className="section">
        <div className="container is-max-desktop">
          <div className="columns">
            <div className="column is-two-thirds-tablet is-half-desktop">
              <Selling />
            </div>
            <div className="column is-one-third-tablet is-half-desktop">
              <Animation />
            </div>
          </div>
          <CompanyList />
        </div>
      </section>
    </div>
  )
}

export default IntroSection;
