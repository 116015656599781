import { css } from '@emotion/react';

const NoTimeSeriesData = ({ target }) => {
  const style = css`
    padding: 20px 20px;
    text-align: center;
  `;
  let message;
  switch (target.status) {
    case 'active': {
      message = <div css={style}>No new users found recently. They will show up soon.</div>;
      break;
    }
    case 'paused': {
      message = <div css={style}>No new users found recently. Resume the scanning of this account to see new data.</div>;
      break;
    }
    case 'completed': {
      message = null;
      break;
    }
    default: {
      message = null;
    }
  }
  return message;
}

export default NoTimeSeriesData;
