import { useEffect, useState, useContext } from 'react';
import { css } from '@emotion/react';
import PurpleBackground from './PurpleBackground';
import { GoLock } from 'react-icons/go';
import { useSnackbar } from 'notistack';
import { Link, Redirect, useLocation } from 'react-router-dom';
import {AxiosContext} from '@context/AxiosContext';

const style = css`
  max-width: 430px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  /* width: 430px; */
  /* margin: 0 auto 20px; */
  .logo {
    text-align: center;
    color: white;
    margin-bottom: 20px;
  }
  .reset-pw-link {
    text-align: center;
    margin-top: 15px;
    a {
      color: white;
      text-decoration: underline;
      font-size: 14px;
    }
  }
  .auth-panel {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid transparent;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    .panel-header {
      margin: 40px 20px 0;
      line-height: 1.5;
      font-size: 24px;
      color: #664986;
      font-family: 'BentonSans';
      text-align: center;
    }
    .panel-form {
      padding: 40px;
      .instructions {
        color: #2d3748;
        margin-bottom: 20px;
        font-size: 14px;
      }
      .label {
        color: #62748e;
        font-size: 12px;
        font-weight: 'BentonSans-Bold';
      }
      input {
        font-size: 14px;
        height: 44px;
        margin-bottom: 10px;
      }
      .icon {
        color: #79589F;
        height: 44px
      }
    }
    .panel-footer {
      text-align: center;
      text-decoration: none;
      padding: 20px;
      display: block;
      font-size: 17px;
      color: #4a5568;
      background-color: #f5f5f5;
      border-top: 1px solid #ddd;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      a {
        color: #79589f;
        text-decoration: underline;
      }
      span {
        color: #4a5568;
        margin: 0 5px;
      }
    }
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
  const query = useQuery();
  const token = query.get('token');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({ password: '', token: token });
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const {myAxios} = useContext(AxiosContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      await myAxios.post('/reset_password', { ...formData });
      setSubmitting(false);
      setSubmitted(true);
      enqueueSnackbar(`We have successfully saved your new password. You can now login.`, {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    } catch(e) {
      setSubmitting(false);
      enqueueSnackbar(e.error?.response?.data || 'Problem resetting password. Please try again.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  if (submitted) {
    return (
      <Redirect to="/login" />
    )
  }

  return (
    <PurpleBackground>
      <div css={style}>
        <div className="auth-panel">
          <h2 className="panel-header">Reset Password</h2>
          <form className="panel-form" onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Password</label>
              <div class="control has-icons-left">
                <input
                  autoFocus
                  className="input"
                  type="password"
                  placeholder="New password"
                  name="password"
                  required
                  value={formData.password}
                  onChange={e => setFormData({
                    ...formData,
                    password: e.target.value
                  })}
                />
                <span class="icon is-small is-left">
                  <GoLock />
                </span>
              </div>
            </div>
            <button type="submit" class="button is-primary is-medium is-fullwidth">{submitting ? 'Saving Password...' : 'Save Password'}</button>
          </form>
          <div className="panel-footer">
            <Link to="/login">Log In</Link>
            <span>or</span>
            <Link to="/sign-up">Sign Up</Link>
          </div>
        </div>
      </div>
    </PurpleBackground>
  )
}

export default ResetPassword;
