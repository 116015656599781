import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const FooterCTA = ({ user, prospect }) => {
  if (user) {
    return (
      <Link
        to="/dashboard"
        className="button is-primary is-small"
      >
        <strong>Go To Your Dashboard</strong>
      </Link>
    )
  }
  if (prospect) {
    return (
      <Link
        to={`/dashboard?pid=${prospect.shortid}`}
        className="button is-primary is-small"
      >
        <strong>Try for Free</strong>
      </Link>
    )
  }
  return (
    <a
      href="https://apps.shopify.com/lookalike-audience-maker"
      className="button is-primary is-small"
    >
      <strong>Try for Free</strong>
    </a>
  )
}
const mapStateToProps = (state) => ({
  user:     state.user,
  prospect: state.prospect
});

export default connect(
  mapStateToProps
)(FooterCTA);
