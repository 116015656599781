import React, { useRef, useEffect } from 'react';
import { connect } from "react-redux";
import { css } from '@emotion/react';
import { NavLink, Switch, Route, Redirect } from 'react-router-dom';
import StatusDot from "@features/Dashboard/StatusDot";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const LoadingCard = () => {

  const style = css`
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    margin-bottom: 14px;
    /* height: 100px; */
    /* border: 1px solid black; */
    .top {
      padding: 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      .name-skeleton {
        margin-left: 10px;
      }
    }
    .bottom {
      background-color: #f5f5f5;
      display: flex;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      .stat {
        .stat-title {
          white-space: nowrap;
          font-size: 10px;
          font-weight: 600;
          text-transform: uppercase;
          color: #9e9e9e;
        }
        .stat-val {
          color: #424242;
        }
      }
    }
  `;

  return (
    <div css={style}>
      <div className="top">
        <Skeleton circle={true} count={1} height={40} width={40} />
        <div className="name-skeleton">
          <Skeleton count={1} width={115} />
        </div>
      </div>
      <div className="bottom">
        <div className="stat">
          <div className="stat-title">
          <Skeleton count={1} width={70} />
          </div>
          <div className="stat-val">
          <Skeleton count={1} width={60} />
          </div>
        </div>
        <div className="stat">
          <div className="stat-title">
          <Skeleton count={1} width={60} />
          </div>
          <div className="stat-val">
          <Skeleton count={1} width={50} />
          </div>
        </div>
        <div className="stat">
          <div className="stat-title">
          <Skeleton count={1} width={90} />
          </div>
          <div className="stat-val">
          <Skeleton count={1} width={50} />
          </div>
        </div>
      </div>
    </div>
  )
}

function WrappedIgAccountCard(props) {
  const myRef = useRef(null);
  
  useEffect(() => {
    setTimeout(() => {
      props.is_match && myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 200)
  }, [props.is_match]);

  const style = css`
    margin-bottom: 10px;
    display: block;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    /* margin-bottom: 14px; */
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #e0e0e0;
    &.active {
      border: 2px solid #79589F;
    }
    .top {
      padding: 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: auto;
          border-radius: 50%;
        }
        .name {
          margin-left: 10px;
          color: #212121;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .right {

      }
    }
    .bottom {
      background-color: #f5f5f5;
      display: flex;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      .stat {
        .stat-title {
          white-space: nowrap;
          font-size: 10px;
          font-weight: 600;
          text-transform: uppercase;
          color: #9e9e9e;
        }
        .stat-val {
          color: #424242;
        }
      }
    }
  `;

  return (
    <NavLink ref={myRef} css={style} to={`/dashboard/${props._id}`}>
      <div className="top">
        <div className="left">
          {props.pic_string && <img src={props.pic_string} alt="profile-pic"/>}
          <div className="name">{props.full_name || props.username }</div>
        </div>
        <div className="right">
          {/* <StatusDot status={props.status} /> */}
        </div>
      </div>
      <div className="bottom">
        <div className="stat">
          <div className="stat-title">
          Emails So Far
          </div>
          <div className="stat-val">
          {props.total_scrapes?.toLocaleString("en-US") || 0}
          </div>
        </div>
        {/* <div className="stat">
          <div className="stat-title">
          Emails
          </div>
          <div className="stat-val">
          {props.total_emails?.toLocaleString("en-US") || 0}
          </div>
        </div> */}
        <div className="stat">
          <div className="stat-title">
          Platform
          </div>
          <div style={{ textTransform: 'capitalize' }} className="stat-val">
          {props.platform}
          </div>
        </div>
      </div>
    </NavLink>
  )
}

const IgAccountCard = React.memo(WrappedIgAccountCard);


const IgAccountList = ({ data_sources, loading, no_render }) => {

  const style = css`
    padding: 20px 20px;
    flex: 1;
    overflow: scroll;
  `;

  if (data_sources.allIds.length === 0 || loading) {
    return (
      <div css={style} className="ig-accounts-list">
        {[...Array(3)].map((_, i) =>
          <LoadingCard key={i}/>
        )}
      </div>
    )
  }
  
  return (
    <div css={no_render ? css`` : style} className="ig-accounts-list">
      <Switch>
        <Route path="/dashboard/:datasource">
          {data_sources.allIds.map((id, i) => {
            return no_render ? null : (
              <Route
                key={i}
                path={`/dashboard/${id}`}
                children={({ match }) => <IgAccountCard is_match={match?.isExact || false} {...data_sources.byId[id]}/> }
              />
            )
          })}
        </Route>
        <Redirect to={`/dashboard/${data_sources.allIds[0]}`} />
      </Switch>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  data_sources: state.dashboard.data_sources,
  loading:      state.dashboard.loading,
  no_render:    ownProps.no_render
});

export default connect(
  mapStateToProps,
  {},
)(IgAccountList);
