import React, { useState, useContext, useEffect } from 'react';
import { connect } from "react-redux";
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { css } from '@emotion/react';
import { GrClose } from 'react-icons/gr';
import { useSnackbar } from 'notistack';
import { request_more_emails, close_add_emails_drawer } from "@actions/dashboard";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import RadioButton from '@features/RadioButton';
import { useParams } from 'react-router-dom';
import { AudienceContext } from '@context/AudienceContext'
import {AxiosContext} from '@context/AxiosContext';

const style = css`
  .drawer-header {
    background: #f7f8fb;
    padding: 12px 20px 10px;
    min-height: 63px;
    border-bottom: 1px solid #e7ebf3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .drawer-header-text {
      color: #475366;
      font-size: 15px;
    }
    .drawer-header-close {
      display: flex;
      cursor: pointer;
    }
  }
  .drawer-body {
    padding: 20px;
    /* display: flex;
    justify-content: center;
    padding: 50px 0; */
    .drawer-body-content {
      font-size: 13px;
      margin-top: 10px;
      color: #475366;
      line-height: 1.5;
      /* max-width: 800px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px; */
      .form-info {
        .info-title {
          font-size: 15px;
          color: #79589f;
        }
        .info-content {
          font-size: 13px;
          margin-top: 10px;
          color: #475366;
          line-height: 1.5;
        }
      }
      .form {
        .label {
          color: #62748e;
          font-size: 12px;
          font-weight: 'BentonSans-Bold';
        }
        input {
          font-size: 14px;
          height: 32px;
        }
        .search-wrapper {
          margin-top: auto;
          button {
            font-family: 'BentonSans-Medium';
            font-size: 13px;
            line-height: 22px;
            height: 32px;
          }
        }
      }
      .separator {
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #eef1f6;
      }
      .submit-form {
        button {
          font-family: 'BentonSans-Medium';
          font-size: 13px;
          line-height: 22px;
          height: 32px;
        }
      }
    }
    .separator {
      margin-top: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #eef1f6;
    }
    .drawer-body-submit {
      button {
        font-family: 'BentonSans-Medium';
        font-size: 13px;
        line-height: 22px;
        height: 32px;
      }
    }
  }
`;

const AddAudienceDrawer = ({
  status,
  add_emails_open,
  request_more_emails,
  close_add_emails_drawer,
  ...ownProps
}) => {
  const myAxios = useContext(AxiosContext);
  const { setAudiences } = useContext(AudienceContext);
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [audienceSize, setAudienceSize] = useState(500);
  const [emailAmounts, setEmailAmounts] = useState([])
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { datasource } = useParams()

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      const { payload: { data }} = await request_more_emails({
        data_source_id: datasource,
        audience_size:  audienceSize
      })
      setAudiences({ type: 'add', data: data })
      close_add_emails_drawer()
    } catch(e) {
      console.log(e)
      enqueueSnackbar(e.error?.response?.data || 'Problem requesting more emails. Try again or contact support.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    } finally {
      setSubmitting(false);
    }
  }
  
  async function fetchEmailAmounts() {
    try {
      const { data } = await myAxios.get('/shopify/email-amounts');
      setEmailAmounts(data);
    } catch(e) {
      enqueueSnackbar(`Problem email quantity options. Try refreshing the page`, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }
  
  useEffect(() => {
    fetchEmailAmounts();
  }, [])

  return (
    <>

      <Drawer
        anchor='right'
        open={add_emails_open}
        onClose={() => close_add_emails_drawer() }
      >
        <Box
          sx={{ width: matches ? '400px' : '100%' }}
          role="presentation"
        >
          <div css={style} className="drawer">
            <div className="drawer-header">
              <div className="empty"></div>
              <div className="drawer-header-text">
              Add Emails to your Audience
              </div>
              <div
                className="drawer-header-close"
                onClick={() => close_add_emails_drawer}
              >
                <GrClose />
              </div>
            </div>
            <div className="drawer-body">
              <div className="drawer-body-content">
              Select how many additional emails you want to request.
              </div>
              <div className="separator"></div>
              <div className="drawer-body-submit">
                <form className="submit-form" onSubmit={handleSubmit}>
                  <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    flexWrap: 'wrap',
                    mb: 2
                  }}>
                    {emailAmounts.map((amount, i) =>
                      <RadioButton
                        name="aud_size"
                        id={`${amount}_emails`}
                        value={amount}
                        isChecked={audienceSize === amount}
                        handleChange={() => setAudienceSize(amount)}
                        small
                      >
                        {amount} Emails
                      </RadioButton>  
                    )}
                  </Box>
                  <button
                    type="submit"
                    className="button is-primary is-medium"
                    disabled={submitting}
                  >
                  { submitting ? 'Submitting...' : 'Submit' }
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  add_emails_open: state.dashboard.add_emails_open,
  ...ownProps
});

const mapDispatchToProps = {
  request_more_emails,
  close_add_emails_drawer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAudienceDrawer);
