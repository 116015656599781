import React from 'react';
import { css } from '@emotion/react';
import { GiCancel } from 'react-icons/gi';

const style = css`
  &.is-private {
    border: 2px solid #e53935;
    .right {
      .cancel-button {
        color: #e53935;
      }
    }
  }
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  border: 2px solid #e0e0e0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    .pic {
      height: 40px;
      width: auto;
      border-radius: 50%;
    }
    .meta {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      .name {
        color: #212121;
        font-size: 14px;
        font-weight: 600;
      }
      .followers {
        font-size: 10px;
        font-weight: 600;
        color: #9e9e9e;
      }
    }
  }
  .right {
    .cancel-button {
      display: flex;
      color: #79589F;
      font-size: 18px;
      cursor: pointer;
    }
  }
`;

const privateWarningStyle = css`
  font-size: 13px;
  margin-top: 10px;
  color: #e53935;
  line-height: 1.5;
`;

const IgSearchResultCard = (props) => {
  const num_followers = props.followers ? props.followers.toLocaleString("en-US") : 0
  return (
    <>
      <div css={style} className={`card ${props.is_private && 'is-private'}`}>
        <div className="left">
          {props.pic_string && <img className="pic" src={props.pic_string} alt="profile-pic"/>}
          <div className="meta">
            <div className="name">{props.name}</div>
            <div className="followers">{num_followers} Followers</div>
          </div>
        </div>
        <div className="right">
          <div className="cancel-button" onClick={() => props.setSearchData(null)}>
            <GiCancel />
          </div>
        </div>
      </div>
      { props.is_private &&
        <div css={privateWarningStyle}>This account is private. We cannot scan private accounts. Click the 'X' and search for a different account.</div>
      }
    </>
  )
}

export default IgSearchResultCard;
