import React, { useState, useEffect, useContext } from 'react';
import { connect } from "react-redux";
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { css } from '@emotion/react';
import { GrClose } from 'react-icons/gr';
import { useSnackbar } from 'notistack';
import {AxiosContext} from '@context/AxiosContext';
import IgSearchResultCard from '@features/Dashboard/IgSearchResultCard';
import { change_target_status } from "@actions/dashboard";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
var FileDownload = require('js-file-download');
var contentDisposition = require('content-disposition');

const useStyles = makeStyles({
  input: {
    height: "30px",
    boxSizing: "border-box" // <-- add this
  }
});

const style = css`
  .drawer-header {
    background: #f7f8fb;
    padding: 12px 20px 10px;
    min-height: 63px;
    border-bottom: 1px solid #e7ebf3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .drawer-header-text {
      color: #475366;
      font-size: 15px;
    }
    .drawer-header-close {
      display: flex;
      cursor: pointer;
    }
  }
  .drawer-body {
    padding: 20px;
    /* display: flex;
    justify-content: center;
    padding: 50px 0; */
    .drawer-body-content {
      font-size: 13px;
      margin-top: 10px;
      color: #475366;
      line-height: 1.5;
      .drawer-body-content__username-highlight {
        font-family: 'BentonSans-Bold';
      }
      .drawer-body-content__small-title {
        font-size: 13px;
        color: #475366;
        font-family: 'BentonSans-Bold';
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .form-info {
        .info-title {
          font-size: 15px;
          color: #79589f;
        }
        .info-content {
          font-size: 13px;
          margin-top: 10px;
          color: #475366;
          line-height: 1.5;
        }
      }
      .form {
        .label {
          color: #62748e;
          font-size: 12px;
          font-weight: 'BentonSans-Bold';
        }
        input {
          font-size: 14px;
          height: 32px;
        }
        .search-wrapper {
          margin-top: auto;
          button {
            font-family: 'BentonSans-Medium';
            font-size: 13px;
            line-height: 22px;
            height: 32px;
          }
        }
      }
      .separator {
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #eef1f6;
      }
      .submit-form {
        button {
          font-family: 'BentonSans-Medium';
          font-size: 13px;
          line-height: 22px;
          height: 32px;
        }
      }
    }
    .low-data-warning {
      background: linear-gradient(0deg, rgba(247,243,251,0.8) 0%, #F7F3FB 100%);
      font-size: 15px;
      color: #79589f;
      padding: 10px;
      border-radius: 4px;
      margin-top: 10px;
    }
    .separator {
      margin-top: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #eef1f6;
    }
    .drawer-body-submit {
      button {
        font-family: 'BentonSans-Medium';
        font-size: 13px;
        line-height: 22px;
        height: 32px;
      }
    }
  }
`;

const DownloadDrawer = ({
  targetId: target_id,
  username,
  status,
  new_scrapes,
  last_download_at,
  change_target_status,
  ...ownProps
}) => {
  const {myAxios} = useContext(AxiosContext);
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [verbs, setVerbs] = useState(['','']);
  const [info, setInfo] = useState('');
  const [form, setForm] = useState({ hasEmail: true, hasPhone: false, sinceLastDownload: false });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      const { data, headers } = await myAxios.post('/download', {
        target_id,
        ...form
      }, {
        responseType: 'blob'
      });
      const { parameters } = contentDisposition.parse(headers['content-disposition']);
      FileDownload(data, parameters.filename);
      setSubmitting(false);
      enqueueSnackbar('Your csv file has downloaded! Click on the file below to view your data.', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
      ownProps.setDrawerOpen(false);
    } catch(e){
      setSubmitting(false);
      const error = await e.response.data.text()
      enqueueSnackbar(error || "Problem downloading data. Please try again.", {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  const handleChange = (event, special = {}) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    setForm({
      ...form,
      ...special,
      [name]: value
    });
  }

  return (
    <>

      <Drawer
        anchor='right'
        open={ownProps.drawerOpen}
        onClose={() => ownProps.setDrawerOpen(false) }
      >
        <Box
          sx={{ width: matches ? '400px' : '100%' }}
          role="presentation"
        >
          <form className="submit-form" onSubmit={handleSubmit}>
            <div css={style} className="drawer">
              <div className="drawer-header">
                <div className="empty"></div>
                <div className="drawer-header-text">
                  Download CSV
                </div>
                <div
                  className="drawer-header-close"
                  onClick={() => ownProps.setDrawerOpen(false)}
                >
                  <GrClose />
                </div>
              </div>
              <div className="drawer-body">
                <div className="drawer-body-content">
                  <>
                    <div>Download followers from <span className="drawer-body-content__username-highlight">{username}</span>:</div>
                    <br/>
                    <div>Below, filter for followers that have an email or phone number.</div>
                    <div className="drawer-body-content__small-title">Download Filters</div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="hasEmail"
                            classes={{ root: classes.input }}
                            checked={form.hasEmail}
                            onChange={(e) => handleChange(e, { hasPhone: false })}
                            sx={{
                              // color: '#79589F',
                              '&.Mui-checked': {
                                color: '#79589F',
                              },
                            }}
                          />
                        }
                        label="Has Email Address"
                        sx={{
                          color: '#323b49',
                          '.MuiFormControlLabel-label': { fontSize: 15 }
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="hasPhone"
                            classes={{ root: classes.input }}
                            checked={form.hasPhone}
                            onChange={(e) => handleChange(e, { hasEmail: false })}
                            sx={{
                              // color: '#79589F',
                              '&.Mui-checked': {
                                color: '#79589F',
                              },
                            }}
                          />
                        }
                        label="Has Phone Number"
                        sx={{
                          color: '#323b49',
                          '.MuiFormControlLabel-label': { fontSize: 15 }
                        }}
                      />
                      <Tooltip title={!!!new_scrapes ? (last_download_at ? 'No new data has been found since your last download.' : 'You haven\'t downloaded any data yet.') : ''}>

                      <FormControlLabel
                        name="sinceLastDownload"
                        disabled={!!!new_scrapes}
                        checked={form.sinceLastDownload}
                        onChange={handleChange}
                        control={
                          <Checkbox
                            classes={{ root: classes.input }}
                            sx={{
                              // color: '#79589F',
                              '&.Mui-checked': {
                                color: '#79589F',
                              },
                            }}
                          />
                        }
                        label="Only Data Since Last Download"
                        sx={{
                          color: '#323b49',
                          '.MuiFormControlLabel-label': { fontSize: 15 }
                        }}
                      />
                      </Tooltip>
                    </FormGroup>
                  </>
                </div>
                { form.hasEmail && form.hasPhone &&
                  <div className="low-data-warning">
                   Be careful filtering data that contains BOTH an email address and phone number because it will greatly reduce the amount of data that will be downloaded.
                  </div>
                }
                <div className="separator"></div>
                <div className="drawer-body-submit">
                  <button
                    type="submit"
                    class="button is-primary is-medium"
                  >
                  {submitting ? 'Downloading...' : 'Download'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Box>
      </Drawer>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = {
  change_target_status
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadDrawer);
