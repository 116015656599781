import { connect } from "react-redux";
import { get_user } from "@actions/user";
import { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useInterval } from '@hooks/useInterval';
import { useSnackbar } from 'notistack';
import {AxiosContext} from '@context/AxiosContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SubscriptionReadiness = ({ get_user }) => {
  const {myAxios} = useContext(AxiosContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();

  useInterval(async () => {
    if (query.has('session_id')) {
      try {
        const { data } = await myAxios.get(`/subscription-readiness?session_id=${query.get('session_id')}`);
        if (data.ready) {
          // NOTE: we get the new user attributes with a direct call to req.user server-side.
          await get_user();
          query.delete('session_id');
          history.replace({
            search: query.toString()
          });
        }
      } catch(e) {
        enqueueSnackbar(`Problem checking subscription status. Try refreshing the page`, {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom'
          }
        });
      }
    }
  }, 3000);

  return null
}

const mapDispatchToProps = {
  get_user
};

export default connect(
  null,
  mapDispatchToProps,
)(SubscriptionReadiness);
