const initialState = {
  loading: true,
  prices_open: false, // NOTE: since, there are a few places needing access to this.
  add_emails_open: false, // same reason as above.
  data_sources: {
    allIds: [],
    byId: {}
  }
};

export function dashboardReducer(state = initialState, action = {}) {
  switch(action.type) {
    case 'GET_QUICK_DASH_SUCCESS': {
      const { data } = action.payload;
      const allIds = data.map(a => a._id);
      const byId = data.reduce((obj, account) => {
        return {
          ...obj,
          [account._id]: {
            ...account
          }
        }
      }, {});
      return {
        ...state,
        loading: false,
        data_sources: {
          ...state.data_sources,
          allIds: allIds,
          byId: byId
        }
      }
    }
    case 'CREATE_DATA_ORDER_SUCCESS': {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        data_sources: {
          ...state.data_sources,
          allIds: [...state.data_sources.allIds, data._id],
          byId: {
            ...state.data_sources.byId,
            [data._id]: data
          }
        }
      }
    }
    case 'ADD_IG_USERNAME_SUCCESS': {
      const { data } = action.payload;
      return {
        ...state,
        data_sources: {
          ...state.data_sources,
          allIds: [...state.data_sources.allIds, data.username],
          byId: {
            ...state.data_sources.byId,
            [data.username]: {
              ...data
            }
          }
        }
      }
    }
    case 'CHANGE_TARGET_STATUS_SUCCESS': {
      const { data } = action.payload;
      return {
        ...state,
        data_sources: {
          ...state.data_sources,
          byId: {
            ...state.data_sources.byId,
            [data.username]: {
              ...state.data_sources.byId[data.username],
              status: data.status
            }
          }
        }
      }
    }
    case 'REQUEST_MORE_EMAILS_SUCCESS': {
      const { data } = action.payload;
      return {
        ...state,
        data_sources: {
          ...state.data_sources,
          byId: {
            ...state.data_sources.byId,
            [data.data_source_id]: {
              ...state.data_sources.byId[data.data_source_id],
              total_scrapes: (state.data_sources.byId[data.data_source_id].total_scrapes || 0) + data.audience_size
            }
          }
        }
      }
    }
    case 'CLOSE_PRICES': {
      return {
        ...state,
        prices_open: false
      }
    }
    case 'OPEN_PRICES': {
      return {
        ...state,
        prices_open: true
      }
    }
    case 'CLOSE_EMAIL_DRAWER': {
      return {
        ...state,
        add_emails_open: false
      }
    }
    case 'OPEN_EMAIL_DRAWER': {
      return {
        ...state,
        add_emails_open: true
      }
    }
    default:
      return state;
  }
}
