import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { connect } from "react-redux";
import { open_prices } from "@actions/dashboard";
import QuotaReset from '@features/Dashboard/QuotaReset';

const style = css`
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  background: linear-gradient(0deg, rgba(247,243,251,0.8) 0%, #F7F3FB 100%);
  border-top: 1px solid #e7ebf3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 140px;
  @media (max-width: 768px) {
    flex: 0 0 110px;
  }
  .dash-bottom {
    max-width: 800px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    .quota-info {
      .info-title {
        font-size: 15px;
        color: #79589f;
      }
      .info-content {
        font-size: 13px;
        margin-top: 10px;
        color: #475366;
        line-height: 1.5;
        .consider-upgrade {
          text-decoration: underline;
          color: #79589f;
          cursor: pointer;
        }
      }
    }
    .quota {
      .quota-top {
        font-size: 13px;
        color: #475366;
        line-height: 1.5;
        display: flex;
        justify-content: space-between;
        font-family: 'BentonSans-Medium';
      }
      .quota-prog-bar {
        margin-top: 5px;
        progress {
          height: 10px;
        }
      }
    }
  }
`;

const QuotaStats = ({ scrapes_quota, scrapes_used, is_trial, open_prices, stripe_subscription_status }) => {
  const [scrapePercent, setScrapePercent] = useState("0");

  useEffect(() => {
    const percent = Math.ceil((scrapes_used/scrapes_quota)*100).toString();
    setScrapePercent(percent);
  }, [scrapes_used]);

  const handleUpgradeClick = async () => {
    await open_prices();
  }

  if (stripe_subscription_status === 'canceled') {
    return null;
  }

  return (
    <div css={style}>
      <div className="dash-bottom">
        <div className="columns">
          <div className="column is-one-third is-hidden-mobile">
            <div className="quota-info">
              <div className="info-title">
              {is_trial ? 'Your ' : 'Monthly ' }Scan Quota
              </div>
              <div className="info-content">
              { is_trial &&
                <div>Welcome to your free trial. You have {Math.max(scrapes_quota - scrapes_used, 0)} scans remaining. If you want more scans, <span className="consider-upgrade" onClick={handleUpgradeClick}>consider upgrading</span>.</div>
              }
              { !is_trial && (scrapes_quota - scrapes_used >= 0) &&
                <div>You have {scrapes_quota - scrapes_used} scans remaining this month. You can always request more than your base amount.</div>
              }
              { !is_trial && (scrapes_quota - scrapes_used < 0) &&
                <div>Just a heads up, you have exceeded your base quota by {Math.abs(scrapes_quota - scrapes_used)} emails this month.</div>
              }
              </div>
            </div>
          </div>
          <div className="column is-two-thirds">
            <div className="quota">
              <div className="quota-top">
                <div className="quota-top__left">
                Scans this {is_trial ? 'Free Trial' : 'Month'}
                </div>
                <div className="quota-top__right">
                {scrapes_used}/{scrapes_quota}
                </div>
              </div>
              <div className="quota-prog-bar">
                <progress
                  className={`progress ${scrapes_used >= scrapes_quota ? 'is-danger' : 'is-primary'}`}
                  value={scrapePercent}
                  max="100"
                >
                {scrapePercent}%
                </progress>
              </div>
              <QuotaReset />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  scrapes_quota:              state.user.scrapes_quota,
  scrapes_used:               state.user.scrapes_used,
  is_trial:                   state.user.is_trial,
  stripe_subscription_status: state.user.stripe_subscription_status
});

const mapDispatchToProps = {
  open_prices: open_prices
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotaStats);
