import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { connect } from "react-redux";
import * as moment from 'moment';

const style = css`
  font-size: 13px;
  color: #757575;
  line-height: 1.5;
  margin-top: 10px;
`;

// NOTE: this component is fitted to shopify only at the moment.

const QuotaReset = ({ is_trial, billing_on }) => {

  if (is_trial) {
    return <div css={style}>If you upgrade to a paid plan the quota will reset monthly.</div>
  }

  return (
    <div css={style}>Your quota resets on {moment(billing_on).format("dddd, MMMM Do YYYY")}.</div>
  )
}

const mapStateToProps = (state) => ({
  is_trial:   state.user.is_trial,
  billing_on: state.user.shopify_subscription?.billing_on
});

export default connect(
  mapStateToProps
)(QuotaReset);
