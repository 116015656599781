import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { open_prices } from "@actions/dashboard";

const style = css`
  background: #d32f2f;
  color: white;
  flex: 0 0 3.25rem;
  font-family: 'BentonSans-Bold';
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
  a {
    margin-left: 5px;
    color: white;
    text-decoration: underline;
  }
`;

function SubscriptionCanceledBanner({ stripe_subscription_status, open_prices }) {

  const handleOpenPrices = async () => {
    await open_prices();
  }

  if (stripe_subscription_status !== 'canceled') {
    return null;
  }

  return (
    <div css={style}><span>Your subscription has been canceled.&nbsp;<a href="#" onClick={handleOpenPrices}>Click here if you'd like to reactivate.</a></span></div>
  );
}

const mapStateToProps = (state) => ({
  stripe_subscription_status: state.user.stripe_subscription_status
});

const mapDispatchToProps = {
  open_prices: open_prices
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionCanceledBanner);
