import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";

const style = css`
  background: #fff9c4;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #e7ebf3;
  .reload {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ReloadTableCTA = ({ accounts, page, setPage }) => {
  let { username } = useParams();
  const data = accounts[username];
  const [totals_history, set_totals_history] = useState({ prev: null, curr: null });

  useEffect(() => {
    if (data?.total_scrapes) {
      set_totals_history({
        prev: !totals_history.prev ? data.total_scrapes : totals_history.prev,
        curr: data.total_scrapes
      })
    }
  }, [data?.total_scrapes]);

  useEffect(() => {
    username && set_totals_history({ prev: null, curr: null });
  }, [username])

  if (!data) {
    return null;
  }

  if (page < 2 || (totals_history.prev === totals_history.curr )) {
    return null;
  }

  return (
    <div css={style}>New data has just been found.&nbsp;
      <span className="reload" onClick={() => {
        setPage(1);
        set_totals_history({ prev: null, curr: null })
      }}>Reload table</span>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  accounts: state.dashboard.data_sources.byId,
  ...ownProps
});

export default connect(
  mapStateToProps,
  {}
)(ReloadTableCTA);
