import { combineReducers } from 'redux';
import { userReducer } from './user_reducer';
import { prospectReducer } from './prospect_reducer';
import { dashboardReducer } from './dashboard_reducer';

const rootReducer = () => combineReducers({
  user:      userReducer,
  prospect:  prospectReducer,
  dashboard: dashboardReducer
});

export default rootReducer;
