import React, { useState, useContext, useEffect } from 'react';
import { connect } from "react-redux";
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { css } from '@emotion/react';
import { GrClose } from 'react-icons/gr';
import { useSnackbar } from 'notistack';
import {AxiosContext} from '@context/AxiosContext';
import IgSearchResultCard from '@features/Dashboard/IgSearchResultCard';
import { useHistory, Route, useParams } from 'react-router-dom';
import IgAccountList from '@features/Dashboard/IgAccountList';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const style = css`
  .drawer-header {
    background: #f7f8fb;
    padding: 12px 20px 10px;
    min-height: 63px;
    border-bottom: 1px solid #e7ebf3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .drawer-header-text {
      color: #323b49;
      font-size: 15px;
    }
    .drawer-header-close {
      display: flex;
      cursor: pointer;
    }
  }
  .drawer-body {
    display: flex;
    justify-content: center;
    .drawer-body-content {
      max-width: 800px;
      width: 100%;
    }
  }
`;

const ViewIgAccountsMobileDrawer = ({
  dash_loading,
  ...ownProps
}) => {
  const history = useHistory();
  const {myAxios} = useContext(AxiosContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { datasource } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    datasource && ownProps.setAcctDrawerOpen(false);
  }, [datasource]);

  return (
    <>
      <Drawer
        anchor='right'
        open={ownProps.acctDrawerOpen}
        onClose={() => ownProps.setAcctDrawerOpen(false) }
      >
        <Box
          sx={{ width: matches ? '400px' : '100%' }}
          role="presentation"
        >
          <div css={style} className="drawer">
            <div className="drawer-header">
              <div className="empty"></div>
              <div className="drawer-header-text">
              View Another Account
              </div>
              <div
                className="drawer-header-close"
                onClick={() => ownProps.setAcctDrawerOpen(false)}
              >
                <GrClose />
              </div>
            </div>
            <div className="drawer-body">
              <div className="drawer-body-content">
                <Route path="/dashboard/:datasource?">
                  <IgAccountList />
                </Route>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  dash_loading:    state.dashboard.loading,
  ...ownProps
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewIgAccountsMobileDrawer);
