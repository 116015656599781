import { css } from '@emotion/react';
import ScaleLoader from "react-spinners/ScaleLoader";

const style = css`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
`

const FullPageLoader = ({ timeLeft }) => {
  return (
    <div css={style}><ScaleLoader height={50} width={8} color={'#79589F'}/>
    {timeLeft}
    </div>
  )
}

export default FullPageLoader;
