import React, { useState } from 'react';
import { css } from '@emotion/react';
import Table from '@features/Dashboard/ScrapePanel/Table';
import TimeSeries from '@features/Dashboard/ScrapePanel/TimeSeries';
import DownloadDrawer from '@features/Dashboard/ScrapePanel/DownloadDrawer';
import LoadingData from '@features/Dashboard/ScrapePanel/LoadingData';
import OpenDownloadDrawerButton from '@features/Dashboard/ScrapePanel/OpenDownloadDrawerButton';
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';

const style = css`
  box-shadow: 0 0 0 1px rgb(89 105 129 / 10%), 0 1px 3px 0 rgb(89 105 129 / 10%), 0 1px 2px 0 rgb(0 0 0 / 5%);
  border-radius: 4px;
  background: white;
  overflow: hidden;
  .data-card__header {
    border-top: 1px solid #e7ebf3;
    border-bottom: 1px solid #e7ebf3;
    padding: 15px;
    font-family: 'BentonSans-Medium';
    .data-card__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .data-card__title__left {
        color: #212121;
      }
      .data-card__title__right {
        button {
          font-family: 'BentonSans-Bold';
        }
      }
    }
  }
  .data-card__body {
    display: flex;
  }
`;

const Data = ({ data_sources, dashLoading }) => {
  let { username } = useParams();
  const data = data_sources[username];
  const [downloadDrawerOpen, setDownloadDrawerOpen] = useState(false);

  if (dashLoading) {
    return null;
  }
  
  // TODO need markup here.

  // if (data?.total_scrapes > 0) {
  //   return (
  //     <>
  //       <div className="columns">
  //         <div className="column is-full">
  //           <div css={style} className="data-card">
  //             <div className="data-card__timeseries">
  //               <TimeSeries/>
  //             </div>
  //             <div className="data-card__header">
  //               <div className="data-card__title">
  //                 <div className="data-card__title__left">
  //                 Followers Scanned So Far
  //                 </div>
  //                 <div className="data-card__title__right">
  //                   <OpenDownloadDrawerButton open={setDownloadDrawerOpen} />
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="data-card__body">
  //               <Table />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <DownloadDrawer
  //         targetId={data._id}
  //         username={data.username}
  //         status={data.status}
  //         new_scrapes={data.new_scrapes}
  //         last_download_at={data.last_download_at}
  //         drawerOpen={downloadDrawerOpen}
  //         setDrawerOpen={setDownloadDrawerOpen}
  //       />
  //     </>
  //   )
  // }

  return (
    <LoadingData data={data}/>
  )
}

const mapStateToProps = (state) => ({
  data_sources: state.dashboard.data_sources.byId,
  dashLoading:  state.dashboard.loading
});

export default connect(
  mapStateToProps,
  {}
)(Data);
