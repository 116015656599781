import React, { useState, useEffect, useContext } from 'react';
import { css } from '@emotion/react';
import { close_prices } from "@actions/dashboard";
import { useSnackbar } from 'notistack';
import { connect } from "react-redux";
import { Route, useLocation, useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import { GrClose } from 'react-icons/gr';
import {AxiosContext} from '@context/AxiosContext';
import ShopifyPriceCard from '@features/Dashboard/ShopifyPriceCard';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const style = css`
  .card {
    .plan-title {
      margin-bottom: 12px;
      font-family: 'BentonSans-Medium';
    }
    .amount-wrapper {
       display: flex;
       align-items: center;
       h3 {
         margin-bottom: 0px;
         font-family: 'BentonSans-Bold';
       }
       .amount-slash {
         margin-right: 5px;
         margin-left: 5px;
       }
    }
    .plan-perks {
      margin-top: 20px;
      .perk {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 10px;
        .checkmark {
          font-size: 20px;
          color: #3161fc;
        }
        .text {
          padding-left: 10px;
          .powerful {
            border-bottom: 3px solid #fa8080;
          }
        }
      }
    }
    .choose-plan-button {
      margin-top: 30px;
      font-family: 'BentonSans-Bold';
    }
  }
`;

const PricesDialog = ({ prices_open, close_prices, provider }) => {
  const myAxios = useContext(AxiosContext);
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [plans, setPlans] = useState({ allIds: [], byId: {} });

  async function fetchPlans() {
    try {
      const { data } = await myAxios.get(`/plans/${provider}`);
      setPlans(data);
    } catch(e) {
      enqueueSnackbar(`Problem fetching plans. Try refreshing the page`, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  const handleClose = async () => {
    await close_prices();
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <Dialog
      open={prices_open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle sx={{
        backgroundColor: '#f7f8fb',
        color: '#323b49',
        fontSize: '15px',
        fontFamily: 'BentonSans',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <>
          {'Simple, Transparent Pricing'}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              fontSize: '14px',
              marginRight: '-10px'
            }}
          >
            <GrClose   />
          </IconButton>
        </>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div css={style} className="columns is-multiline">
          {plans.allIds.map((plan_id, i) =>
            <div className="column is-half" key={i}>
              <ShopifyPriceCard 
                {...{ id: plan_id, ...plans.byId[plan_id]}} 
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = (state) => ({
  prices_open: state.dashboard.prices_open,
  provider:    state.user.provider
});

const mapDispatchToProps = {
  close_prices
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PricesDialog);
