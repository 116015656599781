import { css } from '@emotion/react';

const style = css`
  box-shadow: 0 0 0 1px rgb(89 105 129 / 10%), 0 1px 3px 0 rgb(89 105 129 / 10%), 0 1px 2px 0 rgb(0 0 0 / 5%);
  border-radius: 4px;
  background: white;
  padding: 15px;
  height: 200px;
  font-size: 15px;
  margin-top: 10px;
  color: #475366;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LoadingData = ({ data }) => {
  if (data?.status !== 'active') {
    return null;
  }
  return (
    <div className="columns">
      <div className="column">
        <div css={style} className="data-card">
          <div>Your data will start showing up here in 5-15 minutes...</div>
        </div>
      </div>
    </div>
  )
}

export default LoadingData;
