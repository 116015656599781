import { useEffect, useState, useContext } from 'react';
import { css } from '@emotion/react';
import PurpleBackground from './PurpleBackground';
import { GoPerson, GoLock } from 'react-icons/go';
import { useSnackbar } from 'notistack';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { login } from "@actions/user";
import { connect } from "react-redux";

const style = css`
  max-width: 600px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  /* width: 430px; */
  /* margin: 0 auto 20px; */
  .logged-out-message {
    text-align: center;
    color: white;
    a {
      color: white;
      text-decoration: underline;
    }
  }
  .back-home {
    text-align: center;
    color: white;
    a {
      color: white;
      text-decoration: underline;
    }
  }
  .logo {
    text-align: center;
    color: white;
    margin-bottom: 20px;
  }
  .reset-pw-link {
    text-align: center;
    margin-top: 15px;
    a {
      color: white;
      text-decoration: underline;
      font-size: 14px;
    }
  }
  .auth-panel {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid transparent;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    .panel-header {
      margin: 40px 20px 0;
      line-height: 1.5;
      font-size: 24px;
      color: #664986;
      font-family: 'BentonSans';
      text-align: center;
    }
    .panel-form {
      padding: 40px;
      .label {
        color: #62748e;
        font-size: 12px;
        font-weight: 'BentonSans-Bold';
      }
      input {
        height: 44px;
      }
      .icon {
        color: #79589F;
        height: 44px
      }
    }
    .panel-footer {
      text-align: center;
      text-decoration: none;
      padding: 20px;
      display: block;
      font-size: 17px;
      color: #4a5568;
      background-color: #f5f5f5;
      border-top: 1px solid #ddd;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      span {
        color: #79589f;
        text-decoration: underline;
      }
    }
  }
`;

const Login = ({ login }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      await login(formData);
      setSubmitting(false);
      setSubmitted(true);
    } catch(e) {
      setSubmitting(false);
      enqueueSnackbar(e.error?.response?.data?.message || 'Problem logging in. Please try again.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  if (submitted) {
    return (
      <Redirect to={location?.state?.from || '/dashboard'} />
    )
  }

  return (
    <PurpleBackground>
      <div css={style}>
        {/*<div className="columns">
          <div className="column is-three-fifths is-offset-one-fifth">*/}
            {/*<div className="logo">Their Followers (NEED LOGO...)</div>*/}
            <div className="logged-out-message">To log back into your app or install the app, go to your <a href="https://admin.shopify.com/">Shopify admin portal.</a></div>
            <br/>
            <div className="back-home"><Link to="/">Back to homepage</Link></div>
            {/*<div className="auth-panel">
              <h2 className="panel-header">Log in to your account</h2>
              <form className="panel-form" onSubmit={handleSubmit}>
                <div className="field">
                  <label className="label">Email</label>
                  <div class="control has-icons-left">
                    <input
                      autoFocus
                      className="input is-normal"
                      type="email"
                      placeholder="Email address"
                      name="email"
                      required
                      value={formData.email}
                      onChange={e => setFormData({
                        ...formData,
                        email: e.target.value
                      })}
                    />
                    <span class="icon is-small is-left">
                      <GoPerson />
                    </span>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Password</label>
                  <div class="control has-icons-left">
                    <input
                      className="input is-normal"
                      type="password"
                      placeholder="Password"
                      name="password"
                      required
                      value={formData.password}
                      onChange={e => setFormData({
                        ...formData,
                        password: e.target.value
                      })}
                    />
                    <span class="icon is-small is-left">
                      <GoLock />
                    </span>
                  </div>
                </div>
                <button type="submit" class="button is-primary is-medium is-fullwidth">{submitting ? 'Logging in...' : 'Login'}</button>
              </form>
              <Link to="/sign-up" className="panel-footer">Don't have an account?&nbsp;&nbsp;<span>Sign Up</span></Link>
            </div>
            <div className="reset-pw-link"><Link to="/forgot-password">Forgot your password?</Link></div>*/}
          {/*</div>
        </div>*/}
      </div>
    </PurpleBackground>
  )
}

const mapDispatchToProps = {
  login: login
};

export default connect(
  null,
  mapDispatchToProps,
)(Login);
