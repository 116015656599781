import React, { createContext, useRef, useEffect } from 'react';
import axios from 'axios';

const AxiosContext = createContext(null);
const { Provider } = AxiosContext;

const AxiosInstanceProvider = ({
  config = {},
  requestInterceptors = [],
  responseInterceptors = [],
  children,
}) => {
  const instanceRef = useRef(axios.create(config));

  useEffect(() => {
    requestInterceptors.forEach((interceptor) => {
      instanceRef.current.interceptors.request.use(
        interceptor
      );
    });
    responseInterceptors.forEach((interceptor) => {
      instanceRef.current.interceptors.response.use(
        interceptor
      );
    });
  }, [requestInterceptors, responseInterceptors]);

  return (
    <Provider value={instanceRef.current}>
      {children}
    </Provider>
  );
};

export {AxiosContext, AxiosInstanceProvider};
