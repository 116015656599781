import { useState, useContext } from 'react';
import { css } from '@emotion/react';
import Skeleton from 'react-loading-skeleton'
import { useLocation, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { connect } from "react-redux";
import logo_wide from '../../assets/logo_wide.png';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const style = css`
  .navbar {
    box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 16%);
    background: white;
    .navbar-brand {
      .navbar-item {
        font-family: 'Source Code Pro', monospace;
        color: #475366;
        padding-left: 20px;
        font-weight: 600;
      }
    }
    .navbar-start {
      .navbar-item {
        font-size: 14px;
      }
    }
  }
`;

const NavbarEnd = ({ user, prospect }) => {
  if (user) {
    return (
      <Link
        to="/dashboard"
        className="button is-primary"
      >
        <strong>Go To Your Dashboard</strong>
      </Link>
    )
  }
  if (prospect) {
    return (
      <Link
        to={`/dashboard?pid=${prospect.shortid}`}
        className="button is-primary"
      >
        <strong>Try for Free</strong>
      </Link>
    )
  }
  return (
    <div className="buttons">
      <a
        href="https://apps.shopify.com/lookalike-audience-maker"
        className="button is-primary"
      >
        <strong>Try for Free</strong>
      </a>
      <Link
        to="/login"
        className="button is-light"
      >
        Log in
      </Link>
    </div>
  )
}

const HomeNavbar = ({ user, prospect }) => {
  const [ activeMenu, setActiveMenu ] = useState(false);
  return (
    <div css={style}>
      <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img src={logo_wide} />
          </Link>

          <a
            role="button"
            className={`${activeMenu && 'is-active'} navbar-burger`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={() => setActiveMenu(!activeMenu)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className={`${activeMenu && 'is-active'} navbar-menu`}>
          <div className="navbar-start">
            <a className="navbar-item" href="#pricing">
              Pricing
            </a>
            <a className="navbar-item" href="#faq">
              FAQs
            </a>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <NavbarEnd user={user} prospect={prospect} />
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user:     state.user,
  prospect: state.prospect
});

export default connect(
  mapStateToProps,
  {},
)(HomeNavbar);
