import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import SimpleFixedNav from '@features/SimpleFixedNav';
import ScrollToTop from '@features/ScrollToTop';

const style = css`
  .title {
    margin-top: 60px;
  }
`;

const Privacy = () => {
  return (
    <>
      <ScrollToTop />
      <SimpleFixedNav />
      <section className="section" css={style}>
        <div className="container">
          <h1 class="title">Privacy Policy</h1>
          <h2 class="subtitle">Last updated May 13, 2020</h2>
          <p>
          This privacy notice for __________ ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
          Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.
          <br/><br/>
          </p>
          <h4 class="subtitle is-4">1. WHAT INFORMATION DO WE COLLECT?</h4>
          <h5 className="subtitle is-5">Personal information you disclose to us</h5>
          <p>
          We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
          <br/><br/>
          Sensitive Information. We do not process sensitive information.
          <br/><br/>
          All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
          </p>
          <br/><br/>
          <h5 className="subtitle is-5">Information automatically collected</h5>
          <p>
          We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
          <br/><br/>
          Like many businesses, we also collect information through cookies and similar technologies.
          <br/><br/>
          </p>
          <h4 class="subtitle is-4">2. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
          <p>
          We may need to share your personal information in the following situations:
          <br/><br/>
	        Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
          <br/><br/>
          Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
          <br/><br/>
	        Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions.
          <br/><br/>
          </p>
          <h4 class="subtitle is-4">3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
          <p>
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.
          <br/><br/>
          </p>
          <h4 class="subtitle is-4">4. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
          <p>
          We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
          <br/><br/>
          When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
          <br/><br/>
          </p>
        </div>
      </section>
    </>
  )
}

export default Privacy;
