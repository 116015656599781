export function shopify_change_plan(plan) {
  return {
    type: 'SHOPIFY_CHANGE_PLAN',
    payload: {
      request: {
        method: 'post',
        url: '/shopify/change-plan',
        data: { plan }
      }
    }
  }
}