import React, { useState, useEffect, useContext } from 'react';
import { connect } from "react-redux";
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { css } from '@emotion/react';
import { GrClose } from 'react-icons/gr';
import { useSnackbar } from 'notistack';
import {AxiosContext} from '@context/AxiosContext';
import IgSearchResultCard from '@features/Dashboard/IgSearchResultCard';
import { request_more_emails, close_add_emails_drawer } from "@actions/dashboard";
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import RadioButton from '@features/RadioButton';

const style = css`
  .drawer-header {
    background: #f7f8fb;
    padding: 12px 20px 10px;
    min-height: 63px;
    border-bottom: 1px solid #e7ebf3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .drawer-header-text {
      color: #475366;
      font-size: 15px;
    }
    .drawer-header-close {
      display: flex;
      cursor: pointer;
    }
  }
  .drawer-body {
    padding: 20px;
    /* display: flex;
    justify-content: center;
    padding: 50px 0; */
    .drawer-body-content {
      font-size: 13px;
      margin-top: 10px;
      color: #475366;
      line-height: 1.5;
      /* max-width: 800px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px; */
      .form-info {
        .info-title {
          font-size: 15px;
          color: #79589f;
        }
        .info-content {
          font-size: 13px;
          margin-top: 10px;
          color: #475366;
          line-height: 1.5;
        }
      }
      .form {
        .label {
          color: #62748e;
          font-size: 12px;
          font-weight: 'BentonSans-Bold';
        }
        input {
          font-size: 14px;
          height: 32px;
        }
        .search-wrapper {
          margin-top: auto;
          button {
            font-family: 'BentonSans-Medium';
            font-size: 13px;
            line-height: 22px;
            height: 32px;
          }
        }
      }
      .separator {
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #eef1f6;
      }
      .submit-form {
        button {
          font-family: 'BentonSans-Medium';
          font-size: 13px;
          line-height: 22px;
          height: 32px;
        }
      }
    }
    .separator {
      margin-top: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #eef1f6;
    }
    .drawer-body-submit {
      button {
        font-family: 'BentonSans-Medium';
        font-size: 13px;
        line-height: 22px;
        height: 32px;
      }
    }
  }
`;

const ChangeTaskStatusDrawer = ({
  sourceId: source_id,
  username,
  status,
  add_emails_open,
  change_target_status,
  close_add_emails_drawer,
  ...ownProps
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [audienceSize, setAudienceSize] = useState(500)
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      // await change_target_status({ target_id, status: verbs[2], username });
      await request_more_emails({
        source_id,
        audience_size: audienceSize
      })
      setSubmitting(false);
      setSubmitted(true);
      ownProps.setDrawerOpen(false);
    } catch(e) {
      setSubmitting(false);
      enqueueSnackbar(e.error?.response?.data || 'Problem changing IG account status. Please try again.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  // useEffect(() => {
  //   switch(status) {
  //     case 'active': {
  //       setVerbs(['Pause', 'Pausing...', 'paused']);
  //       setInfo(`This will stop the scanning of ${username}. You can resume the scanning at anytime, unless the account has been completely scanned.`)
  //       break;
  //     }
  //     case 'paused': {
  //       setVerbs(['Activate', 'Activating...', 'active']);
  //       setInfo(`This will resume the scanning of ${username}. Afterwards, you can pause the scanning at anytime to focus scanning other IG accounts.`);
  //     }
  //   }
  // }, [status, username]);

  return (
    <>

      <Drawer
        anchor='right'
        open={add_emails_open}
        onClose={() => close_add_emails_drawer() }
      >
        <Box
          sx={{ width: matches ? '400px' : '100%' }}
          role="presentation"
        >
          <div css={style} className="drawer">
            <div className="drawer-header">
              <div className="empty"></div>
              <div className="drawer-header-text">
              Add Emails to your Audience
              </div>
              <div
                className="drawer-header-close"
                onClick={() => close_add_emails_drawer}
              >
                <GrClose />
              </div>
            </div>
            <div className="drawer-body">
              <div className="drawer-body-content">
              Select how many additional emails you want to request.
              </div>
              <div className="separator"></div>
              <div className="drawer-body-submit">
                <form className="submit-form" onSubmit={handleSubmit}>
                  <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    flexWrap: 'wrap',
                    mb: 2
                  }}>
                    <RadioButton
                      name="aud_size"
                      id="100_emails"
                      value={100}
                      isChecked={audienceSize === 100}
                      handleChange={() => setAudienceSize(100)}
                      small
                    >
                      100 Emails
                    </RadioButton>                      
                    <RadioButton
                      name="aud_size"
                      id="200_emails"
                      value={200}
                      isChecked={audienceSize === 200}
                      handleChange={() => setAudienceSize(200)}
                      small
                    >
                      200 Emails
                    </RadioButton> 
                    <RadioButton
                      name="aud_size"
                      id="500_emails"
                      value={500}
                      isChecked={audienceSize === 500}
                      handleChange={() => setAudienceSize(500)}
                      small
                    >
                      500 Emails
                    </RadioButton> 
                  </Box>
                  <button
                    type="submit"
                    class="button is-primary is-medium"
                  >
                  Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  add_emails_open: state.dashboard.add_emails_open,
  ...ownProps
});

const mapDispatchToProps = {
  request_more_emails,
  close_add_emails_drawer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeTaskStatusDrawer);
