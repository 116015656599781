import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { css } from '@emotion/react';
import {AxiosContext} from '@context/AxiosContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const style = css`
  button {
    border: 1px solid white;
    background: transparent;
    color: white;
    margin-left: 10px;
    &:hover {
      border: 1px solid white;
      color: white;
    }
  }
`;

const Unsubscribe = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const action = query.get('action');
  const [ showUnsubDialog, setShowUnsubDialog ] = useState(false);
  const {myAxios} = useContext(AxiosContext);

  const handleClose = (key) => {
    closeSnackbar(key);
    query.delete('action');
    history.replace({
      search: query.toString()
    });
  }

  const handleUnsubscribe = async (key) => {
    try {
      const pid = query.get('pid');
      await myAxios.post('/unsubscribe', { pid });
      enqueueSnackbar('You have been unsubscribed. But please take a look at the data. We can really help with boosting your sales.', {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    } catch(e) {
      enqueueSnackbar('There was a problem unsubscribing.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    } finally {
      closeSnackbar(key);
      query.delete('action');
      history.replace({
        search: query.toString()
      });
    }
  }

  const custom_action = key => (
    <div css={style}>
      <button
        className="button is-small"
        onClick={() => handleUnsubscribe(key)}>
          Unsubscribe Anyways
      </button>
      <button
        className="button is-small"
        onClick={() => handleClose(key)}>
          Nevermind
      </button>
    </div>
  );

  useEffect(() => {
    setShowUnsubDialog(action === 'unsub');
  }, [action]);

  useEffect(() => {
    showUnsubDialog && enqueueSnackbar('Before you unsubscribe, checkout the sample data on this page.', {
      variant: 'warning',
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom'
      },
      persist: true,
      action: custom_action
    });
  }, [showUnsubDialog])


  return null;
}

export default Unsubscribe;
