import { connect } from "react-redux";
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

const style = css`
  height: 100%;
  background-color: #3a2778;
  .columns {
    height: 100%;
    .content {
      text-align: center;
      .content-title {
        font-family: 'BentonSans-Light';
        color: white;
        font-size: 30px;
        margin-bottom: 30px;
      }
      .content-m1 {
        color: white;
        font-size: 16px;
      }
      hr {
        opacity: 0.3;
        height: 1px;
        margin: 2em 0;
        border: 0;
        background-color: rgba(228,228,231,0);
        background-image: linear-gradient(to right, rgba(228,228,231,0), rgba(228,228,231,0.9) 10%, rgba(228,228,231,0.9) 90%, rgba(228,228,231,0));
      }
      .narrow {
        padding: 16px;
        p {
          margin-bottom: 30px;
          font-size: 14px;
          color: white;
          strong {
            color: white;
            font-family: 'BentonSans-Bold'
          }
          a {
            color: #afa0bf;
            text-decoration: underline;
            transition: color 0.1s linear;
            background-color: transparent;
          }
        }
      }
    }
  }
`;

const ConfirmEmail = ({ user, children }) => {
  if (user && !user.email_verified) {
    return (
      <div css={style}>
        <div className="columns is-vcentered is-centered is-mobile">
          <div className="column is-one-third-desktop">
            <div className="content">
              <h1 className="content-title">Almost there...</h1>
              <p className="content-m1">Please check your email ({user.email}) to confirm your account.</p>
              <hr/>
              <div className="narrow">
                <p>If <strong>{user.email}</strong> is not your email address, please <Link to="/sign-up">go back</Link> and enter the correct one.</p>
                <p>If you haven't received our email in 15 minutes, please check your spam folder.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return children;
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(
  mapStateToProps,
)(ConfirmEmail);
