const initialState = null;

export function userReducer(state = initialState, action = {}) {
  switch(action.type) {
    case 'GET_USER_OR_PROSPECT_SUCCESS': {
      const { data: { user } } = action.payload;
      return user || false;
    }
    // NOTE: GET_USER is for after new subscription is ready, we
    // req.user from the user and update the ui with the new user
    // record containing the new subscription data.
    case 'GET_USER_SUCCESS': {
      const { data } = action.payload;
      return {
        ...state,
        ...data
      }
    }
    case 'CREATE_USER_FROM_PID_SUCCESS': {
      const { data: user } = action.payload;
      return user;
    }
    case 'SIGN_UP_SUCCESS': {
      const { data } = action.payload;
      return {
        ...state,
        ...data
      }
    }
    case 'LOGIN_SUCCESS': {
      const { data } = action.payload;
      return {
        ...state,
        ...data
      }
    }
    case 'LOGIN_WITH_TOKEN_SUCCESS': {
      const { data } = action.payload;
      return {
        ...state,
        ...data
      }
    }
    case 'LOGOUT_SUCCESS': {
      return false;
    }
    case 'CREATE_DATA_ORDER_SUCCESS': {
      const { audience_size } = action.payload.data
      return {
        ...state,
        scrapes_used: state.scrapes_used + audience_size
      }
    }
    case 'REQUEST_MORE_EMAILS_SUCCESS': {
      const { audience_size } = action.payload.data
      return {
        ...state,
        scrapes_used: state.scrapes_used + audience_size
      }
    }
    default:
      return state;
  }
}
