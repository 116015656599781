import { useState, useContext } from 'react';
import { css } from '@emotion/react';
import { connect } from "react-redux";
import { logout } from "@actions/user";
import { open_prices } from "@actions/dashboard";
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {AxiosContext} from '@context/AxiosContext';
import logo_wide from '../../assets/logo_wide.png';

const style = css`
  border-bottom: 1px solid #e7ebf3;
  .navbar-brand {
    .navbar-item {
      font-family: 'Source Code Pro', monospace;
      color: #475366;
      padding-left: 20px;
      font-weight: 600;
    }
  }
  .navbar-end {
    .navbar-item {
      button {
        font-family: 'BentonSans-Bold';
      }
    }
  }
`;

const Navbar = ({ user, logout, open_prices }) => {
  const {myAxios} = useContext(AxiosContext);
  const { enqueueSnackbar } = useSnackbar();
  const [ activeMenu, setActiveMenu ] = useState(false);
  const [ loggingOut, setLoggingOut ] = useState(false);
  const [openingStripePortal, setOpeningStripePortal] = useState(false);

  const handleLogout = async () => {
    try {
      setLoggingOut(true);
      await logout();
      setLoggingOut(false);
    } catch(e) {
      setLoggingOut(false);
      enqueueSnackbar(e.error?.response?.data || 'Problem logging out. Please try again.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  const handleOpeningStripePortal = async () => {
    try {
      setOpeningStripePortal(true);
      const { data: url } = await myAxios.post(`/customer-portal`, {});
      window.location.href = url;
    } catch(e) {
      setOpeningStripePortal(false);
      enqueueSnackbar(`Problem opening subscription portal. Please try again.`, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  const handleOpenPrices = async () => {
    await open_prices();
  }

  return (
    <nav css={style} className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item " to="/">
          <img src={logo_wide} />
        </Link>

        <a
          role="button"
          className={`${activeMenu && 'is-active'} navbar-burger`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setActiveMenu(!activeMenu)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className={`${activeMenu && 'is-active'} navbar-menu`}>

        <div className="navbar-start">
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <button
              className="button is-outlined is-primary is-small"
              onClick={handleOpenPrices}
            >
              { user.stripe_subscription_status === 'canceled' ? 'Reactivate' : 'Upgrade' }
            </button>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">
            { user &&

              <span>Welcome, {user.email.substring(0, user.email.indexOf('@'))}!</span>
            }
            </a>

            <div className="navbar-dropdown is-right">
              { user.stripe_customer_id &&
                <a onClick={handleOpeningStripePortal} className="navbar-item">
                  {openingStripePortal ? 'Opening...' : 'Manage Subscription' }
                </a>
              }
              <div className="navbar-item" style={{ fontSize: '12px'}}>Shop: {user.shopify_domain}</div>
              <hr class="navbar-divider"/>
              <a onClick={handleLogout} className="navbar-item">
                {loggingOut ? 'Logging out...' : 'Logout' }
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = {
  logout:      logout,
  open_prices: open_prices
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Navbar);
