import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import FooterCTA from '@features/Home/FooterCTA';

const style = css`
  background: #fafafa;
  padding-top: 30px;
  padding-bottom: 30px;
  .level-left {
    .text {
      font-size: 13px;
      color: #666;
      line-height: 1.8em;
      -webkit-font-smoothing: antialiased;
      a {
        color: #666;
        text-decoration: underline;
      }
    }
  }
`;
const Footer = () => {
  return (
    <section className="section" css={style}>
      <div className="container">
        <nav className="level">
          <div className="level-left">
              <p className="level-item text">©&nbsp;2023 Lookalike Audience Maker</p>
              <p className="level-item text"><Link to="/terms-of-service">Terms of Service</Link></p>
              <p className="level-item text"><Link to="/privacy">Privacy</Link></p>
              <p className="level-item"><FooterCTA /></p>
          </div>
        </nav>
      </div>
    </section>
  )
}

export default Footer;
