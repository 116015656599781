import { css } from '@emotion/react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const PricingLink = ({ user, prospect }) => {
  const style = css`
    margin-top: 30px;
    font-family: 'BentonSans-Bold';
  `

  if (user) {
    return (
      <Link
        to="/dashboard"
        className="button is-fullwidth is-medium is-primary"
        css={style}
      >
      Go To Your Dashboard
      </Link>
    )
  }
  if (prospect) {
    return (
      <Link
        to={`/dashboard?pid=${prospect.shortid}`}
        className="button is-fullwidth is-medium is-primary"
        css={style}
      >
      Try for Free
      </Link>
    )
  }
  return (
    <a
      href="https://apps.shopify.com/lookalike-audience-maker"
      css={style}
      className="button is-fullwidth is-medium is-primary"
    >
    Try for Free
    </a>
  )
}

const mapStateToProps = (state) => ({
  user:     state.user,
  prospect: state.prospect
});

export default connect(
  mapStateToProps
)(PricingLink)
