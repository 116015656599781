import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import logo_wide from '../assets/logo_wide.png';

const style = css`
  background: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  .navbar-brand {
    .navbar-item {
      font-family: 'Source Code Pro', monospace;
      color: #475366;
      padding-left: 20px;
      font-weight: 600;
    }
  }
`;

const SimpleFixedNav = () => {
  return (
    <nav
      css={style}
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img alt="logo" src={logo_wide} />
          </Link>
        </div>
    </nav>
  )
}

export default SimpleFixedNav;
