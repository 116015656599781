import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AnalyticsContext from '@context/AnalyticsContext'

function usePageViews() {
  const location = useLocation();
  const analytics = useContext(AnalyticsContext);
  useEffect(() => {
    analytics?.page(location.pathname);
  }, [analytics, location])
}

const PageViews = () => {
  usePageViews();
  return null;
}

export default PageViews;
