import { css } from '@emotion/react';

const style = css`
    .title {
      font-family: BentonSans-Bold;
      margin-bottom: 30px;
    }
    .faq-wrap {
      .faq-title {
        font-size: 18px;
        color: #3F3F44;
        font-family: 'BentonSans-Medium';
        margin-bottom: 0.5em;
      }
      .faq-content {
        font-size: .9em;
        color: #3F3F44;
        margin-bottom: 30px;
      }
    }
  }
`;

const FAQs = () => {
  return (
    <section className="section" id="faq" css={style}>
      <div className="container is-max-desktop">
        <div className="columns is-mobile is-centered">
          <div className="column has-text-centered">
            <h1 className="title is-2 is-size-3-mobile is-spaced">Frequently Asked Questions</h1>
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-one-third">
            <div className="faq-wrap">
              <div className="faq-title">
              How Quickly Can Emails Be Retrieved?
              </div>
              <p className="faq-content">
              We scan 100K followers in about 24hrs. When our user base surges, we scale up more resources to ensure a fast scan time. Our software is cloud-based and does not require you to have an Instagram or TikTok account.
              </p>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="faq-wrap">
              <div className="faq-title">
              Why Use This Audience Building Tool?
              </div>
              <p className="faq-content">
              If you are trying to create performant Lookalike Audiences for any target market, this tool is for you. In addition to emails, our scanned data also includes names & sometimes phone numbers. Some of our customers use this rich data to fuel their cold email campaigns.
              </p>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="faq-wrap">
              <div className="faq-title">
              How Does It Work?
              </div>
              <p className="faq-content">
              Our lead gen platform is the easiest to use on the market. Simply enter an IG or TikTok account you wish to scan. We will email you when we have finished scanning the account. You can then return to the application and download your list of emails. Our tool is completely cloud based and you don't need an Instragram or TikTok account to use it.
              </p>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="faq-wrap">
              <div className="faq-title">
              Can I Try For Free?
              </div>
              <p className="faq-content">
              Yes, absolutely. The first 1,000 scans are totally free, no credit card is required. This will give you a dataset large enough for you to understand what kind of data we provide and detemine if this tool is useful or not. You have nothing to lose, try it now for free.
              </p>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="faq-wrap">
              <div className="faq-title">
              What Are The Different Plans Available?
              </div>
              <p className="faq-content">
              We offer 4 different plans, Starter, Growth, Pro, and Enterprise. The plans determine how many users you can scan per month. The larger plans cost more, but you have a larger volume of scans, and the cost per scan goes down.
              </p>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="faq-wrap">
              <div className="faq-title">
              Does 100K Scans = 100K Emails?
              </div>
              <p className="faq-content">
              Yes - we filter out Instagram & TikTok users who don't have an email address or make it publically available. So you get what you pay for.
              </p>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="faq-wrap">
              <div className="faq-title">
              What Is Your Refund Policy?
              </div>
              <p className="faq-content">
              If our software is not working we will give you a full refund.
              </p>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="faq-wrap">
              <div className="faq-title">
              How Much Volume Can You Handle?
              </div>
              <p className="faq-content">
              We have no limit to how much volume we can handle. Our system is cloud based and can efficiently scale resources to accommodate any number of application users. If you desire more volume than our current plans provide, please contact us and we can give you a custom plan.
              </p>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="faq-wrap">
              <div className="faq-title">
              Is Scanning Legal?
              </div>
              <p className="faq-content">
              Yes - scanning data from public Instagram and TikTok webpages is 100% legal. All data we scan & download can be retrieved publicly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQs;
