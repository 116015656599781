import React, { useCallback, useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { open_add_emails_drawer } from "@actions/dashboard";
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {AxiosContext} from '@context/AxiosContext';
import Skeleton from 'react-loading-skeleton';
import { useInterval } from '@hooks/useInterval';
import { AudienceContext } from '@context/AudienceContext'
import { Box, CircularProgress, Fade, Paper, Tooltip, useTheme } from '@mui/material';
import Pluralize from 'react-pluralize'
import * as moment from 'moment';
import { FiClock } from 'react-icons/fi'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { TransitionGroup } from 'react-transition-group';
import axios from 'axios';
import { useDetectAdBlock } from "adblock-detect-react";
var FileDownload = require('js-file-download');
var contentDisposition = require('content-disposition');

const style = css`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      margin-bottom: 0;
    }
  }
`;

const ProgressLoader = ({ status }) => {
  let loader;
  const theme = useTheme()
  switch (status) {
    case 'analyzing': {
      loader = <CircularProgress size={20}/>
      break;
    }
    case 'building_file': {
      loader = <CircularProgress size={20}/>
      break;
    }
    case 'ready': {
      loader = <IoMdCheckmarkCircle size={"1.5rem"} color={theme.palette.success.main}/>
      break;
    }
    default: {
      loader = <CircularProgress size={20}/>
    }
  }
  return loader
}

const ProgressPhrase = ({ status }) => {
  let phrase;
  switch (status) {
    case 'analyzing': {
      phrase = 'Gathering data...'
      break;
    }
    case 'taking_long': {
      phrase = 'Taking longer than usual, but we\'re working on it...'
      break;
    }
    case 'building_file': {
      phrase = 'Gathering data...'
      break;
    }
    case 'ready': {
      phrase = 'Ready to download.'
      break;
    }
    default: {
      phrase = 'Gathering data...'
    }
  }
  return phrase
}

const AudienceList = () => {
  const { datasource } = useParams()
  const { audiences, setAudiences } = useContext(AudienceContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme()
  const myAxios = useContext(AxiosContext);
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const adBlockDetected = useDetectAdBlock();
  
  const handleDownload = (id) => {
    if (adBlockDetected) {
      enqueueSnackbar(`If download is not starting, you may need to turn off your ad block extension.`, {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
    axios({
      url: `${process.env.REACT_APP_API}/download/${id}`,
      method: 'GET',
      responseType: 'blob',
      withCredentials: true
    }).then((response) => {
      var { parameters } = contentDisposition.parse(response.headers['content-disposition']);
      FileDownload(response.data, parameters.filename);
    }).catch(e => {
      enqueueSnackbar(`Problem downloading file, please try again.`, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    });
  }
  
  const fetchData = useCallback(async () => {
    if (datasource) {
      try {
        const { data } = await myAxios.post('/get_data_orders', { 
          data_source_id: datasource
        })
        setAudiences({ type: 'get', data: data})
      } catch(e) {
        setError(true)
        enqueueSnackbar('Problem loading audience list.', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom'
          }
        });
      } finally {
        setLoaded(true)
      }
    }
  }, [datasource, enqueueSnackbar, myAxios, setAudiences])
    
  useEffect(() => {
    fetchData()
  }, [fetchData])
  
  useInterval(() => {
    fetchData();
  }, 1000 * process.env.REACT_APP_INTERVAL_DATA_TABLE);
  
  // if (error) {
  //   <div>Error...</div>
  // }
    
  if (!loaded) {
    return (
      <Paper sx={{ mt: 3 }}>
        <Box sx={{ bgcolor: 'grey.200', p: 2, fontWeight: 600 }}>
          <Skeleton sx={{ bgcolor: 'grey.900' }} variant="rounded"  width={60} height={30} />
        </Box>
        <Box p={2}>
        {[...Array(1)].map((_, i) => 
          <Box key={i} sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            p: 1
          }}>
            <Box sx={{ display: 'flex'}}>
              <Box mr={2}><Skeleton variant="rounded" width={80} height={30}  /></Box>
              <Skeleton variant="rounded" width={60} height={30} />
            </Box>
            <Box sx={{ display: 'flex'}}>
              <Skeleton variant="rounded" width={80} height={30}  />
            </Box>
          </Box>
        )}
        </Box>
      </Paper>
    )
  }
  
  return (
    <Paper sx={{ mt: 3 }}>
      <Box sx={{ bgcolor: 'grey.200', p: 2, fontWeight: 600 }}>
        <Pluralize singular={'audience'} plural={'audiences'} count={audiences.length} />
      </Box>
      <TransitionGroup>
      {audiences.map((p, i) =>
        <Fade key={i}>
        <Box sx={{ 
          height: '75px', 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2,
          borderBottom: `1px solid ${theme.palette.grey['300']}`,
          '&:last-child': {
            borderBottom: 'none'
          }
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <Box sx={{ display: 'flex', width: '30px', alignItems: 'center', justifyContent: 'center'}}>
            <ProgressLoader status={p.status} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
              <Box sx={{ fontWeight: 600 }}>{p.audience_size} emails</Box>
              <Box sx={{ fontSize: '13px', fontWeight: 600, color: 'grey.700' }}><ProgressPhrase status={p.status} /></Box>
            </Box>
          </Box>
          <Box sx={{
            display: 'flex'
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 2,
              fontSize: '14px'
            }}>
              <Box sx={{ display: 'flex', mr: 0.5 }}>
                <FiClock />
              </Box>
              <div>{moment(p.created_at).from()}</div>
            </Box>
            <Tooltip 
              title={p.status !== 'ready' ? 'Data not ready yet.' : ""}
              placement="top"
            >
              <span>
            <button
              disabled={p.status !== 'ready'}
              style={p.status !== 'ready' ? { pointerEvents: 'none' } : {}}
              className={`button ${p.status !== 'ready' ? 'is-outlined' : ''} is-primary is-small`}
              onClick={() => handleDownload(p._id)}
            >
              Download
            </button>
            </span>
            </Tooltip>
          </Box>
        </Box>
        </Fade>
      )}
      </TransitionGroup>
    </Paper>
  )
}

const Audiences = ({ open_add_emails_drawer }) => {

  return (
    <div css={style}>
      <div className="header">
        <h4 className="title is-4">All audiences</h4>
        <div className="header_button">
          <button
            className="button is-primary"
            onClick={() => open_add_emails_drawer()}
          >
            <strong>Create Another Audience</strong>
          </button>
        </div>
      </div>
      <Box>
        <AudienceList />
      </Box>
    </div>
  )
}

const mapDispatchToProps = {
  open_add_emails_drawer
};

export default connect(
  null,
  mapDispatchToProps,
)(Audiences);