import { css } from '@emotion/react';

const style = css`
  min-height: 100%;
  display: flex;
  background-image: linear-gradient(to bottom,#654a86,#534292);
  background-color: #534292;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const PurpleBackground = ({ children }) => {
  return (
    <div css={style}>
      {children}
    </div>
  )
}

export default PurpleBackground;
