import React from 'react';
import { connect } from "react-redux";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    background: 'linear-gradient(0deg, rgba(247,243,251,1) 0%, #F7F3FB 100%)',
    color: '#475366',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(13),
    border: '1px solid #e7ebf3',
    fontFamily: 'BentonSans-Medium',
    padding: '10px',
    lineHeight: 1.5,
  },
}));

const OpenDownloadDrawerButton = ({ open, stripe_subscription_status }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  let button;
  switch (stripe_subscription_status) {
    case 'active': {
      button = (
        <button
          className="button is-primary is-small"
          onClick={() => open(true)}
        >
          Download CSV
        </button>
      )
      break;
    }
    default: {
      button = (
        <HtmlTooltip
          title={
            <div>{`You need a subscription to download a CSV. Click the purple "Upgrade" button ${ matches ? 'above.' : 'in the menu above.'}`}</div>
          }
          placement="left"
        >
          <button
            className="button is-primary is-small"
          >
            Download CSV
          </button>
        </HtmlTooltip>
      )
      break;
    }
  }
  return button;
}

const mapStateToProps = (state) => ({
  stripe_subscription_status: state.user?.stripe_subscription_status
});

export default connect(
  mapStateToProps,
  {}
)(OpenDownloadDrawerButton);