import { useEffect, useContext } from 'react';
import AnalyticsContext from '@context/AnalyticsContext';
import { connect } from "react-redux";

function useIdentify(user) {
  const analytics = useContext(AnalyticsContext);
  
  useEffect(() => {
    user && analytics?.identify(user._id, {
      email:     user.email,
      createdAt: user.created_at,
      prospect:  !!user.prospect,
    });
  }, [analytics, user?._id])
  
}

const Identify = ({ user }) => {
  useIdentify(user);
  return null;
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(
  mapStateToProps
)(Identify);
