import React from 'react';
import { css } from '@emotion/react';
import image_lg from '../../assets/locks_goodness.png';
import image_fm from '../../assets/fitmamma.png';
import image_if from '../../assets/instafruta.png';
import image_pp from '../../assets/pure_protein.png';
import image_sm from '../../assets/sport_mem.png';

const style = css`
  margin-top: 110px;
  .column {
    -webkit-transition: all 50ms ease;
    transition: all 50ms ease;
    img {
      max-width: 100px;
      -webkit-filter: grayscale(100%);
      opacity: .65;
      filter: grayscale(100%);
      &:hover {
        opacity: 1;
        filter: grayscale(0%);
      }
    }
  }
  .additional-company {
    text-align: center;
    .powerful-yellow {
      border-bottom: 2px solid #ffdb62;
    }
  }
`

const CompanyList = () => {
  return (
    <div css={style}>
      <div className="columns is-mobile is-multiline is-centered is-vcentered">
        <div className="column is-narrow">
          <img src={image_sm} />
        </div>
        <div className="column is-narrow">
          <img src={image_lg} />
        </div>
        <div className="column is-narrow">
          <img src={image_fm} />
        </div>
        <div className="column is-narrow">
          <img src={image_if} />
        </div>
        <div className="column is-narrow">
          <img src={image_pp} />
        </div>
      </div>
      <div className="additional-company">
        As well as <span className="powerful-yellow">Twitch</span> and used by many more.
      </div>
    </div>
  )
}

export default CompanyList;
