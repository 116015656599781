import { useEffect, useState, useContext } from 'react';
import { css } from '@emotion/react';
import {AxiosContext} from '@context/AxiosContext';
import { useSnackbar } from 'notistack';
import PricingLink from '@features/Home/PricingLink';

const style = css`
    background-image: linear-gradient(0deg, rgba(247,243,251,0.8) 0%, #F7F3FB 100%);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
    border-top: 1px solid rgba(207,215,230,0.5);
    z-index: 5;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 164px;
      background: url("https://www2.assets.heroku.com/assets/podcast/codeish/background-lines-light-a83758788eb0ed57975cba1742c038d88c4b9fb32476716191df6648a2732f9c.svg") no-repeat;
      background-size: 100% 164px;
      background-position: bottom center;
    }
    .section-title {
      text-align: center;
      font-family: BentonSans-Bold;
    }
    .subtitle {
      margin-bottom: 30px;
      font-family: BentonSans-Medium;
    }
    .card {
      .plan-title {
        margin-bottom: 12px;
        font-family: 'BentonSans-Medium';
      }
      .amount-wrapper {
         display: flex;
         align-items: center;
         h3 {
           margin-bottom: 0px;
           font-family: 'BentonSans-Bold';
         }
         .amount-slash {
           margin-right: 5px;
           margin-left: 5px;
         }
      }
      .plan-perks {
        margin-top: 20px;
        .perk {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          margin-bottom: 10px;
          .checkmark {
            font-size: 20px;
            color: #3161fc;
          }
          .text {
            padding-left: 10px;
            .powerful {
              border-bottom: 3px solid #fa8080;
            }
            &.small {
              font-size: 14px;
            }
          }
        }
      }
      .choose-plan-button {
        margin-top: 30px;
        font-family: 'BentonSans-Bold';
      }
    }
  }
`;

const Pricing = () => {
  const myAxios = useContext(AxiosContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [plans, setPlans] = useState({ allIds: [], byId: {} });
  
  async function fetchPlans() {
    try {
      // NOTE: hardcoding shopify plans for now since that's all we support
      // at the moment.
      const { data } = await myAxios.get(`/plans/shopify`);
      setPlans(data);
    } catch(e) {
      enqueueSnackbar(`Problem fetching plans. Try refreshing the page`, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <section className="section" id="pricing" css={style}>
      <div className="container is-max-desktop">
        <div className="columns">
          <div className="column has-text-centered">
            <h1 className="title section-title is-2 is-size-3-mobile is-spaced">Simple, Transparent Pricing</h1>
            <p className="subtitle is-5">If you're not 100% satisfied, we will refund you.</p>
          </div>
        </div>
        <div className="columns is-multiline">
          {plans.allIds.map((plan_id, i) =>
            <div className="column is-half" key={i}>
              <div className="card">
                <div className="card-content">
                  <div className="content">
                    <h2 className="plan-title title is-5">{plans.byId[plan_id].name}</h2>
                    <div className="amount-wrapper">
                      <h3 className="title is-2">${plans.byId[plan_id].price}</h3>
                      <div><span className="amount-slash">/</span>Month</div>
                    </div>
                    <div className="plan-perks">
                      <div className="perk">
                        <div className="text">
                          <span className="powerful">{plans.byId[plan_id].email_quota.toLocaleString()}</span> emails / month
                        </div>
                      </div>
                      <div className="perk">
                        <div className="text small">
                          <span>${plans.byId[plan_id].add_more.rate}</span> per additional email
                        </div>
                      </div>
                    </div>
                    <PricingLink />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Pricing;
