import { css } from '@emotion/react';

const style = css`
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url('https://www3.assets.heroku.com/assets/home/hero-background-newswatch-c4897c103cbbe54eca6f805c74eafb0321adc408dc681e8d75a6cc70be85bcdb.svg');
    background-size: 1400px auto;
    background-repeat: no-repeat;
    background-position: center bottom -2px;
    opacity: 0.65;
  }
  .section-title {
    text-align: center;
    /* text-transform: uppercase; */
    /* letter-spacing: .125em; */
    font-size: 30px;
    color: #79589F;
    margin-bottom: 1.5em;
  }
  .my-panel {
    padding: 20px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    background: #F9F9FB;
    box-shadow: 0 2px 4px 0 rgb(17 17 17 / 12%);
    .my-panel-title {
      color: #56595D;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: .125em;
      padding: 10px 0;
    }
    .my-panel-content {
      color: rgb(63, 63, 68);
      font-size: 14px;
      p {
        margin-bottom: 5px;
      }
      a {
        color: #1969ca;
        text-decoration: none;
        font-family: 'BentonSans-Medium';
      }
    }
  }
`;

const UsedFor = () => {
  return (
    <section className="section" id="used-for" css={style}>
      <h6 className="section-title">I Downloaded the Emails, Now What?</h6>
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="my-panel">
              <div className="my-panel-title">
              Direct Email Marketing
              </div>
              <div className="my-panel-content">
              <p>Use your preferred email sending tool and send an email to each of the contacts in your new list and introduce them to your products or services. As a bonus, we include the user's name in each scanned contact so you can create personal emails that convert well. <br/><br/>If you are planning on sending a large volume of marketing emails, we suggest you try <a rel="nofollow" href="https://www.gmass.co/">GMASS</a>. GMASS allows you to send a high volume of emails through your Gmail account and is reasonably priced. When indiviuals reply to your marketing emails, they will show up in your Gmail inbox which is very convenient.</p>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="my-panel">
              <div className="my-panel-title">
              Direct SMS Marketing
              </div>
              <div className="my-panel-content">
              The data we download also includes phone numbers, which means you can use your favorite text message sending tool to reach your new contacts and tell them about your products or services. Just like with email marketing, we suggest using the contact's name to create a personal message, i.e: "Hi &#123;name&#125;, we thought you'd be interested in xyz. Here's a coupon."<br/><br/>There are many capable SMS tools to perform the sending. We suggest searching "Bulk SMS" and select a platform that fits with your budget.
              </div>
            </div>
          </div>
          <div className="column">
            <div className="my-panel">
              <div className="my-panel-title">
              Lookalike Audiences
              </div>
              <div className="my-panel-content">
                <p>Do you run ads on Facebook? Then this tool is for you. <a rel="nofollow" href="https://www.facebook.com/business/help/164749007013531?id=401668390442328">A lookalike audience</a> is a list of emails you provide Facebook when running an ad campaign, and Facebook will deliver your ad to people who are interested in things similar to your lookalike audience. Long story short, your ads will convert better and be much more profitable.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UsedFor;
