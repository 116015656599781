import { connect } from "react-redux";
import { create_user_from_pid } from "@actions/user";
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { css } from '@emotion/react';

const style = css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'BentonSans-Bold';
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreateUserFromPid = ({ user, create_user_from_pid, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const pid = query.get('pid');

  const createUserFromPid = async () => {
    try {
      await create_user_from_pid(pid);
    } catch(e) {
      enqueueSnackbar('Problem creating user profile. Try refreshing the page.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  useEffect(() => {
    pid && createUserFromPid();
  }, [pid]);

  useEffect(() => {
    if (user) {
      const queryParams = new URLSearchParams(location.search)
      if (queryParams.has('pid')) {
        queryParams.delete('pid');
        history.replace({
          search: queryParams.toString(),
        })
      }
    }
  }, [user]);

  if (!query.has('pid')) {
    return children;
  }

  return (
    <div css={style}>Creating your dashboard, this will take less than 5 seconds...</div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  ...ownProps
});

const mapDispatchToProps = {
  create_user_from_pid
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateUserFromPid);
