import React, { useState, useEffect } from 'react';
import Navbar from '@features/Dashboard/Navbar';
import { css } from '@emotion/react';
import { GoPlus } from 'react-icons/go';
import AddIgAccountDrawer from '@features/Dashboard/AddIgAccountDrawer';
import ViewIgAccountsMobileDrawer from '@features/Dashboard/ViewIgAccountsMobileDrawer';
import IgAccountList from '@features/Dashboard/IgAccountList';
import ScrapePanel from '@features/Dashboard/ScrapePanel';
import ScrapePanelEmpty from '@features/Dashboard/ScrapePanelEmpty';
import QuotaStats from '@features/Dashboard/QuotaStats';
import PricesDialog from '@features/Dashboard/PricesDialog';
import { get_quick_dash } from "@actions/dashboard";
import { useSnackbar } from 'notistack';
import { connect } from "react-redux";
import { Route, useLocation, useHistory } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { useInterval } from '@hooks/useInterval';
import SubscriptionCanceledBanner from '@features/Dashboard/SubscriptionCanceledBanner';
import SubscriptionPastDueBanner from '@features/Dashboard/SubscriptionPastDueBanner';
import ShopifyErrorBanner from '@features/Dashboard/ShopifyErrorBanner';
import UnknownIgAccount from '@features/Dashboard/UnknownIgAccount';

const style = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  .dash-experience-warning {
    background: #ffee58;
    flex: 0 0 2.5rem;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .dash-top {
    flex: 0 0 3.25rem;
  }
  .dash-middle {
    overflow: auto;
    flex: 1;
    display: flex;
    /* overflow: hidden; */
    @media (max-width: 768px) {
      flex-direction: column;
      overflow: auto;
    }
    .ig-accounts-panel {
      flex: 0 0 320px;
      border-right: 1px solid #e7ebf3;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        border-right: none;
        flex: none;
        /* flex: none; */
        overflow: hidden;
      }
      .ig-accounts-header {
        flex: 0 0 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        border-bottom: 1px solid #e7ebf3;
        /* background-color: rgb(247, 248, 251); */
        .ig-accounts-header__text {
          color: #79589f;
          font-size: 15px;
        }
        .view-another-button {
          margin-right: 5px;
        }
        button {
          font-family: 'BentonSans-Bold';
          background-color: white;
          &:hover {
            background-color: rgba(121,88,159,.1);
            color: #79589F;
          }
          &:focus {
            color: #79589F;
          }
          /* background-color: white; */
          /* &:hover {
            background-color: rgba(121,88,159,.1);
            color: #79589F;
          } */
        }
      }
    }
    .scrape-panel {
      overflow: hidden;
      flex: 1;
      background: #fafafa;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        /* border-top: 1px solid #e7ebf3; */
        overflow: hidden;
      }
      /* background-color: #f5f5f5; */
    }
  }
`;

const Dashboard = ({
  user,
  prospect,
  get_quick_dash,
  create_user_from_pid,
  data_sources
}) => {
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [acctDrawerOpen, setAcctDrawerOpen] = useState(false);

  const fetchQuickDash = async () => {
    try {
      await get_quick_dash();
    } catch(e) {
      enqueueSnackbar('Problem getting dashboard data. Try refreshing the page.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }
  }

  useEffect(() => {
    if (user) {
      fetchQuickDash()
      // NOTE: identify session in Mouseflow.
      window._mfq = window._mfq || []
      window._mfq.push(['identify', user.shopify_domain])
      window._mfq.push(['setVariable', 'shopify', user.shopify_domain])
    }
  }, [user?._id]);

  useInterval(() => {
    user && fetchQuickDash();
  }, 1000 * Number(process.env.REACT_APP_INTERVAL_QUICK_DASH));

  useEffect(() => {
    if (user === false && prospect === false) {
      history.push('/login', {
        from: location.pathname
      })
    }
  }, [user, prospect]);

  return (
    <>
      <div css={style}>
        <div className="dash-experience-warning is-hidden-desktop">
        We recommend a desktop browser for the best possible experience.
        </div>
        <SubscriptionCanceledBanner />
        <SubscriptionPastDueBanner />
        <ShopifyErrorBanner />
        <div className="dash-top">
          <Navbar />
        </div>
        <div className="dash-middle">
          <div className="ig-accounts-panel">
            <div className="ig-accounts-header">
              <div className="ig-accounts-header__text is-hidden-mobile">
                Data Sources
              </div>
              <div>
                <MediaQuery maxWidth={768}>
                { data_sources.allIds.length > 1 &&
                  <button
                    className="view-another-button button is-primary is-outlined is-small"
                    onClick={() => setAcctDrawerOpen(true)}
                  >
                    View Another Account
                  </button>
                }
                </MediaQuery>
                <button
                  className="button is-primary is-outlined is-small"
                  onClick={() => setDrawerOpen(true)}
                >
                  Add New Data Source
                </button>
              </div>
            </div>
            <MediaQuery minWidth={769}>
              <Route path="/dashboard/:datasource?">
                <IgAccountList />
              </Route>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
              <Route path="/dashboard/:datasource?">
                <IgAccountList no_render={true} />
              </Route>
            </MediaQuery>
          </div>
          <div className="scrape-panel">
            <Route path="/dashboard" exact>
              <ScrapePanelEmpty />
            </Route>
            <Route path="/dashboard/:datasource">
              <ScrapePanel />
            </Route>
            <Route path="/dashboard/:datasource">
              <UnknownIgAccount />
            </Route>
          </div>
        </div>
        <QuotaStats />
      </div>
      <AddIgAccountDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
      <MediaQuery maxWidth={768}>
        <Route path="/dashboard/:datasource?">
          <ViewIgAccountsMobileDrawer
            acctDrawerOpen={acctDrawerOpen}
            setAcctDrawerOpen={setAcctDrawerOpen}
          />
        </Route>
      </MediaQuery>
      <PricesDialog />
    </>
  )
}

const mapStateToProps = (state) => ({
  user:         state.user,
  prospect:     state.prospect,
  data_sources: state.dashboard.data_sources,
});

const mapDispatchToProps = {
  get_quick_dash,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
