const initialState = null;

export function prospectReducer(state = initialState, action = {}) {
  switch(action.type) {
    case 'GET_USER_OR_PROSPECT_SUCCESS': {
      const { data: { prospect } } = action.payload;
      return prospect || false;
    }
    case 'LOGOUT_SUCCESS': {
      return false;
    }
    default:
      return state;
  }
}
