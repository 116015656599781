import Navbar from './Navbar';
import IntroSection from './IntroSection';
// import SignUpSection from './SignUpSection';
import ShopifySection from './ShopifySection';
import TestimonialSection from './TestimonialSection';
import UsedFor from './UsedFor';
import Pricing from './Pricing';
import FAQs from './FAQs';
import Video from './Video';
import Footer from '@features/Home/Footer';
import Unsubscribe from '@features/Home/Unsubscribe';

const Home = () => {
  return (
    <div className="home">
      <Navbar />
      <IntroSection />
      {/* <SignUpSection /> */}
      <ShopifySection />
      <TestimonialSection />
      <Pricing />
      <Video />
      <FAQs />
      <Footer />
      <Unsubscribe />
    </div>
  )
}

export default Home;
