import { createStore, compose, applyMiddleware } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import createRootReducer from './reducers/root_reducer';

// MIDDLEWARES
import thunk from 'redux-thunk';
import { createLogger } from "redux-logger";
const logger = createLogger();

const middlewareConfig = {
  returnRejectedPromiseOnError: true
}

export default function configureStore(initialState, client) {
  let middleware = [];
  if (process.env.NODE_ENV === 'development') {
    middleware = [
      ...middleware,
      axiosMiddleware(client, middlewareConfig),
      thunk,
      logger
    ];
  } else {
    middleware = [
      ...middleware,
      axiosMiddleware(client, middlewareConfig),
      thunk
    ];
  }
  const store = createStore(
    createRootReducer(),
    initialState,
    compose(
      applyMiddleware(...middleware)
    )
  );
  return store;
}
